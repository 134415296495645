/* CharityPro Donation and Fundraisng HTML Template */

@media only screen and (max-width: 1540px){
	.fluid-section-one .fluid-column .inner{
		padding-left:0px;
		text-align:center;
	}

	.fluid-section-one .fluid-column .inner .icon-box{
		position:relative;
		margin:0 auto 30px;
	}

	.testimonial-style-one .owl-nav .owl-next,
	.testimonial-style-two .owl-nav .owl-next{
		right:-60px;
	}

	.testimonial-style-one .owl-nav .owl-prev,
	.testimonial-style-two .owl-nav .owl-prev{
		left:-60px;
	}
}

@media only screen and (max-width: 1340px){
	.page-wrapper{
		overflow:hidden;
	}

	.fluid-section-one .fluid-column .inner h2{
		font-size:30px;
	}
}

@media only screen and (max-width: 1140px){

	.main-header{
		position:relative;
		margin:0px !important;
	}

	.main-header.header-style-two{
		position:relative;
		top:0px;
		background:#333333;
		padding:15px 0px;
	}

	.main-header .header-upper .logo-box,
	.main-header .header-upper .upper-right{
		width:100%;
		text-align:center;
	}

	.main-header .header-upper .upper-right{
		padding-top: 0;
		padding-bottom: 20px;
	}

	.main-header .header-upper .info-box{
		display:inline-block;
		text-align:left;
		float:none;
		margin:0px 20px 30px;
	}

	.main-header .header-upper .social{
		top:-23px;
	}

	.main-header .nav-outer{
		width:100%;
	}

	.header-style-two .nav-outer{
		margin-top:20px;
	}

	.main-header .main-box .logo-box{
		width:100%;
		text-align:center;
		padding-bottom:0px;
	}

	.main-menu .navigation > li{
		padding:0px;
		margin-left:0px;
	}

	.page-title{
		padding:80px 0px;
	}

	.sidebar-page-container .sidebar{
		margin-left:0px;
		padding-left:0px;
	}

	.sidebar-page-container .content-side{
		padding-right:15px;
	}

	.testimonial-style-one .owl-nav .owl-next,
	.testimonial-style-one .owl-nav .owl-prev,
	.testimonial-style-two .owl-nav .owl-next,
	.testimonial-style-two .owl-nav .owl-prev{
		display:none;
	}

	.fluid-section-one .fluid-column{
		width:50%;
	}

	.fluid-section-one .fluid-column:last-child{
		width:100%;
	}

	.fullwidth-section-one .content-column .inner{
		padding-left:20px;
	}

	.contact-section .contact-column .inner{
		padding-left:0px;
	}


}

@media only screen and (min-width: 768px){
	.main-menu .navigation > li > ul,
	.main-menu .navigation > li > ul > li > ul{
		display:block !important;
		visibility:hidden;
		opacity:0;
	}
}

@media only screen and (max-width: 1023px){

	.sticky-header{
		display:none;
	}

	.main-header .nav-outer{
		padding-right:0px;
	}

	.main-menu .navigation > li > a{
		padding-left:10px;
	}

	.header-style-two .main-menu .navigation > li > a{
		padding-left:0px;
	}

	.main-header .nav-outer .donate-btn{
		top:7px;
		padding-top:12px;
		padding-bottom:12px;
		display:none;
		z-index:20;
	}

	.header-style-two .nav-outer .donate-btn{
		top:7px;
	}

	.call-to-action h1{
		font-size:42px;
	}

	.fullwidth-section-one .content-column,
	.fullwidth-section-one .image-column{
		position:relative;
		width:100%;
		float:left;
	}

	.fullwidth-section-one .image-column{
		background-image:none;
		height:auto;
	}

	.fullwidth-section-one .image-column .image{
		display:block;
	}

	.fullwidth-section-one .content-column .inner{
		padding:50px 20px 70px;
		max-width:none;
		text-align:center;
	}

	/*.sec-title h2,*/
	.default-title h2{
		font-size:26px;
	}

}

@media only screen and (max-width: 850px){

	.main-header .header-upper .social{
		top:0px;
	}

}

@media only screen and (max-width: 767px){

	.main-header .header-upper .info-box{
		display:block;
		text-align:center;
		padding:0px;
		margin:0px 0px 30px;
	}

	.main-header .header-upper .info-box .icon{
		position:relative;
		left:0px;
		top:0px;
		width:50px;
		margin:0 auto 5px;
		text-align:center;
	}

	.main-header .header-upper .social .social-links li{
		float:none;
		display:inline-block;
		margin:0px 5px;
	}

	.main-header .main-box .logo-box{
		padding-bottom:20px;
	}

	.main-header .nav-outer .donate-btn{
		display:block;
		border-radius:3px;
		padding: 10px 15px;
	}

	.main-header .search-box-outer .dropdown-menu{
		top:40px;
		left:0px;
		right:auto;
	}

	.main-header .logo-outer {
		position:relative;
		left:0px;
		top:0px;
		right:0px;
		bottom:0px;
		width:100%;
		margin:0px;
		padding:20px 0px;
	}

	.main-header .header-top-one .top-right,
	.main-header .header-top-one .top-left{
		width:100%;
	}

	.main-header .header-top-one .top-right ul,
	.main-header .header-top-one .top-left ul{
		text-align:center;
		width:100%;
		margin:5px 0px;
		padding:0px;
	}

	.main-header .header-top-one .top-right ul li,
	.main-header .header-top-one .top-left ul li{
		margin:0px 10px;
		padding:0px;
		border:none;
		display:inline-block;
		float:none;
	}

	.main-header .main-menu{
		padding-top:0px;
		width:100%;
		margin:0px;
	}

	.main-menu .collapse {
	   max-height:300px;
		overflow:auto;
		float:none;
		width:100%;
		padding:10px 0px 0px;
		border:none;
		margin:0px;
		-ms-border-radius:3px;
		-moz-border-radius:3px;
		-webkit-border-radius:3px;
		-o-border-radius:3px;
		border-radius:3px;
   }

	.main-menu .collapse.in,
	.main-menu .collapsing{
		padding:0px 0px 0px;
		border:none;
		overflow:auto;
		margin:20px 0px 15px;
		-ms-border-radius:3px;
		-moz-border-radius:3px;
		-webkit-border-radius:3px;
		-o-border-radius:3px;
		border-radius:3px;
	}


	.main-menu .navbar-header{
		position:relative;
		float:none;
		display:block;
		text-align:left;
		width:100%;
		padding:15px 0px;
		right:0px;
		z-index:12;
	}

	.main-menu .navbar-header .navbar-toggle{
		display:inline-block;
		z-index:7;
		border:1px solid #3d4554;
		float:none;
		margin:0px 0px 0px 0px;
		border-radius:0px;
		background:#1a1e25;
	}

	.main-menu .navbar-header .navbar-toggle .icon-bar{
		background:#ffffff;
	}

	.main-menu .navbar-collapse > .navigation{
		float:none !important;
		margin:0px !important;
		width:100% !important;
		background:#242933;
	}

	.main-menu .navbar-collapse > .navigation > li{
		margin:0px !important;
		float:none !important;
		width:100%;
	}

	.main-menu .navigation > li > a,
	.main-menu .navigation > li > ul:before{
		border:none;
	}

	.main-menu .navbar-collapse > .navigation > li > a{
		padding:10px 10px !important;
		border:none !important;
	}

	.main-menu .navigation li.dropdown > a:after,
	.main-menu .navigation > li.dropdown > a:before,
	.main-menu .navigation > li > ul > li > a::before,
	.main-menu .navigation > li > ul > li > ul > li > a::before{
		color:#ffffff !important;
		right:15px;
		font-size:16px;
		display:none !important;
	}

	.main-menu .navbar-collapse > .navigation > li > ul,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul,
	.main-menu .navigation > li > ul,
	.main-menu .navigation > li > ul > li > ul{
		position:relative;
		border:none;
		float:none;
		visibility:visible;
		opacity:1;
		display:none;
		margin:0px;
		left:auto !important;
		right:auto !important;
		top:auto !important;
		width:100%;
		background:#1a1e25;
		-webkit-border-radius:0px;
		-ms-border-radius:0px;
		-o-border-radius:0px;
		-moz-border-radius:0px;
		border-radius:0px;
		transition:none !important;
		-webkit-transition:none !important;
		-ms-transition:none !important;
		-o-transition:none !important;
		-moz-transition:none !important;
		-webkit-transform:scale(1) !important;
		-ms-transform:scale(1) !important;
		-o-transform:scale(1) !important;
		-moz-transform:scale(1) !important;
		transform:scale(1) !important;
	}

	.main-menu .navbar-collapse > .navigation > li > ul,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul{
		border-top:1px solid rgba(0,0,0,0.15) !important;
	}

	.main-menu .navbar-collapse > .navigation > li,
	.main-menu .navbar-collapse > .navigation > li > ul > li,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul > li{
		border-top:1px solid rgb(50, 55, 66) !important;
		opacity:1 !important;
		top:0px !important;
		left:0px !important;
		visibility:visible !important;
	}

	.main-menu .navbar-collapse > .navigation > li > ul{
		background: #353a42;
	}
	.main-menu .navbar-collapse > .navigation > li > ul > li{
    border-top: none !important;
    padding-left: 10px;
	}
	.main-menu .navbar-collapse > .navigation > li > ul > li > a{
		background: none !important;
	}
	.main-menu .navigation > li > ul > li > a:after{
		display: none;
	}

	.main-menu .navbar-collapse > .navigation > li:first-child{
		border:none;
	}

	.main-menu .navbar-collapse > .navigation > li > a,
	.main-menu .navbar-collapse > .navigation > li > ul > li > a,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul > li > a{
		padding:10px 10px !important;
		line-height:22px;
		color:#ffffff;
		background:#1a1e25;
		text-align:left;
	}

	.main-menu .navbar-collapse > .navigation > li > a:hover,
	.main-menu .navbar-collapse > .navigation > li > a:active,
	.main-menu .navbar-collapse > .navigation > li > a:focus{
		background:#1a1e25;
	}

	.main-menu .navbar-collapse > .navigation > li:hover > a,
	.main-menu .navbar-collapse > .navigation > li > ul > li:hover > a,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul > li:hover > a,
	.main-menu .navbar-collapse > .navigation > li.current > a,
	.main-menu .navbar-collapse > .navigation > li.current-menu-item > a{
		background:#3d4554;
		color:#ffffff !important;
	}

	.main-menu .navbar-collapse > .navigation li.dropdown .dropdown-btn{
		display:block;
	}

	.main-menu .navbar-collapse > .navigation li.dropdown:after,
	.main-menu .navigation > li > ul:before{
		display:none !important;
	}

	.main-slider,
	.page-title{
		margin-top:0px !important;
	}

	.main-slider .tp-caption{
		display:none !important;
	}

	/*.sec-title h2,*/
	.default-title h2,
	.default-event-post .inner-box .post-header h3{
		font-size:20px;
	}

	.page-title h1,
	.call-to-action h2,
	.news-style-one h3,
	.about-section .content-column h2,
	.contact-section h2{
		font-size:24px;
	}

	/* CALL TO ACTION */
	.call-to-action p.sub{
	  font-size:24px;
	}

	.call-to-action p.main{
	  font-size:32px;
	}
	/* FINE CALL TO ACTION */

	.fluid-section-one .fluid-column{
		width:100%;
	}

	.featured-cause-section .content-column .inner{
		padding-left:0px;
	}

	.call-to-action h1,
	.become-volunteer .content-box h2 strong,
	.cause-details .upper-box h3,
	.fullwidth-section-one .content-column h2,
	.donation-section .upper-content h2,
	.single-cause-section .recent-causes .title h2,
	.main-footer .footer-bottom .bottom-title{
		font-size:32px;
		display:block;
	}

	.news-style-one .image-column{
		margin-bottom:30px;
	}

	.news-style-one.boxed-style .inner-box{
		padding:30px 20px;
	}

	.service-box .inner-box{
		text-align:center;
	}

	.service-box .inner-box .title{
		padding-left:0px;
	}

	.service-box .inner-box .icon-box{
		position:relative;
		left:0px;
		top:0px;
		margin:0 auto 20px;
	}

	.contact-section .contact-column .inner{
		margin-left:0px;
		padding-left:0px;
		text-align:center;
	}

	.contact-section .contact-info li{
		padding-left:0px;
	}

	.contact-section .contact-info li .icon{
		position:relative;
		left:0px;
		top:0px;
		margin:0 auto 20px;
	}

	.become-volunteer .content-box{
		max-width:none;
		padding:40px 20px 50px;
	}

	.cause-details .cause-header .donate-link{
		float:left;
	}

	.cause-details .content .list-style-one{
		margin-left:0px;
	}

	.donation-form-outer{
		padding:40px 20px 50px;
	}

	.single-cause-section .recent-causes-carousel .owl-nav{
		position:relative;
		top:0px;
		margin:30px 0px 0px -10px;
	}

	.main-header .header-upper .info-box{
		margin-bottom: 10px;
	}
	.main-header .header-upper .social{
		top: 10px;
	}

}

@media only screen and (max-width: 599px){

  .featured-cause-section .title h2 {
    font-size: 30px;
  }

  .call-to-action p.main {
    font-size: 40px;
  }

	.comments-area .comment-box{
		padding:30px 20px;
	}

	.sidebar-page-container .comments-area .reply-comment,
	.sidebar-page-container .comments-area .comment .comment-inner,
	.comments-area .reply-comment{
		padding-left:0px;
		margin-left:0px;
	}

	.sidebar-page-container .comments-area .comment-box .author-thumb{
		position:relative;
		left:0px;
		top:0px;
		margin-bottom:20px;
	}

	.comments-area .comment .reply-btn{
		position:relative;
		top:10px;
	}

	.event-style-two .over-content{
		position:relative;
		left:0px;
		top:0px;
		bottom:0px;
		right:0px;
		padding:30px 0px;
	}

	.event-style-three .inner-box{
		position:relative;
		padding-left:0px;
		min-height:0px;
	}

	.event-style-three .inner-box .image-box{
		position:relative;
		width:100%;
		height:auto;
		margin-bottom:20px;
	}

	.default-event-post .inner-box{
		padding-left:0px;
	}

	.default-event-post .inner-box .date{
		position:relative;
		left:0px;
		top:0px;
		margin:0px 0px 15px;
	}

	.style-two .time-countdown .counter-column{
		width:50%;
	}

	.style-one .time-countdown .counter-column{
		margin:0px 10px 10px;
	}

	.style-one .time-countdown .counter-column .count{
		font-size:18px;
	}

	.default-event-post .image-box .overlay-box{
		opacity:1;
		visibility:visible;
	}

	.default-event-post .image-box .overlay-box .time-counter{
		bottom:10px;
	}


	.sec-title h2,
	.sec-title h3,
	.fullwidth-section-one .content-column h1{
		font-size: 32px;
	}


}

@media only screen and (min-width: 767px) and (max-width: 793px){
	.service-box .icon-box{
		display: none;
	}
	.service-box .inner-box .title{
		padding-left: 0;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1187px){
	.service-box .icon-box{
		display: none;
	}
	.service-box .inner-box .title{
		padding-left: 0;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1062px){
	.service-box .inner-box{
		min-height: 250px;
	}
}