
@font-face {
  font-family: "Flaticon";
  src: url("../fonts/flaticon.eot");
  src: url("../fonts/flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/flaticon.woff") format("woff"),
       url("../fonts/flaticon.ttf") format("truetype"),
       url("../fonts/flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-loading:before { content: "\f100"; }
.flaticon-play-button-3:before { content: "\f101"; }
.flaticon-dollar-bills:before { content: "\f102"; }
.flaticon-coin-1:before { content: "\f103"; }
.flaticon-music:before { content: "\f104"; }
.flaticon-multimedia-1:before { content: "\f105"; }
.flaticon-food-2:before { content: "\f106"; }
.flaticon-rest:before { content: "\f107"; }
.flaticon-weather:before { content: "\f108"; }
.flaticon-fashion:before { content: "\f109"; }
.flaticon-tool:before { content: "\f10a"; }
.flaticon-paint:before { content: "\f10b"; }
.flaticon-cloud:before { content: "\f10c"; }
.flaticon-printer-1:before { content: "\f10d"; }
.flaticon-organs-donation:before { content: "\f10e"; }
.flaticon-donation-1:before { content: "\f10f"; }
.flaticon-donation:before { content: "\f110"; }
.flaticon-location-pin:before { content: "\f111"; }
.flaticon-location-marker:before { content: "\f112"; }
.flaticon-print-button:before { content: "\f113"; }
.flaticon-printer:before { content: "\f114"; }
.flaticon-at:before { content: "\f115"; }
.flaticon-email:before { content: "\f116"; }
.flaticon-forms:before { content: "\f117"; }
.flaticon-idea-1:before { content: "\f118"; }
.flaticon-message:before { content: "\f119"; }
.flaticon-padlock:before { content: "\f11a"; }
.flaticon-interface-6:before { content: "\f11b"; }
.flaticon-home-3:before { content: "\f11c"; }
.flaticon-envelope-1:before { content: "\f11d"; }
.flaticon-trophy-1:before { content: "\f11e"; }
.flaticon-heart-3:before { content: "\f11f"; }
.flaticon-chat:before { content: "\f120"; }
.flaticon-money-5:before { content: "\f121"; }
.flaticon-bell:before { content: "\f122"; }
.flaticon-trophy:before { content: "\f123"; }
.flaticon-target:before { content: "\f124"; }
.flaticon-link-2:before { content: "\f125"; }
.flaticon-stick-man:before { content: "\f126"; }
.flaticon-technology-3:before { content: "\f127"; }
.flaticon-placeholder-3:before { content: "\f128"; }
.flaticon-speech-bubble:before { content: "\f129"; }
.flaticon-heart-2:before { content: "\f12a"; }
.flaticon-bar-chart:before { content: "\f12b"; }
.flaticon-user-1:before { content: "\f12c"; }
.flaticon-close:before { content: "\f12d"; }
.flaticon-arrows-14:before { content: "\f12e"; }
.flaticon-arrows-13:before { content: "\f12f"; }
.flaticon-up-arrow:before { content: "\f130"; }
.flaticon-interface-5:before { content: "\f131"; }
.flaticon-idea:before { content: "\f132"; }
.flaticon-eye-1:before { content: "\f133"; }
.flaticon-arrows-12:before { content: "\f134"; }
.flaticon-happiness-1:before { content: "\f135"; }
.flaticon-happiness:before { content: "\f136"; }
.flaticon-anchor-1:before { content: "\f137"; }
.flaticon-medal:before { content: "\f138"; }
.flaticon-play:before { content: "\f139"; }
.flaticon-unlink:before { content: "\f13a"; }
.flaticon-user:before { content: "\f13b"; }
.flaticon-users-1:before { content: "\f13c"; }
.flaticon-users:before { content: "\f13d"; }
.flaticon-placeholder-2:before { content: "\f13e"; }
.flaticon-home-2:before { content: "\f13f"; }
.flaticon-diamond:before { content: "\f140"; }
.flaticon-menu-2:before { content: "\f141"; }
.flaticon-add-1:before { content: "\f142"; }
.flaticon-success:before { content: "\f143"; }
.flaticon-placeholder-1:before { content: "\f144"; }
.flaticon-cup:before { content: "\f145"; }
.flaticon-arrows-11:before { content: "\f146"; }
.flaticon-right-arrow:before { content: "\f147"; }
.flaticon-line-3:before { content: "\f148"; }
.flaticon-cross-2:before { content: "\f149"; }
.flaticon-cross-1:before { content: "\f14a"; }
.flaticon-cogwheel-1:before { content: "\f14b"; }
.flaticon-home-1:before { content: "\f14c"; }
.flaticon-shapes:before { content: "\f14d"; }
.flaticon-arrows-10:before { content: "\f14e"; }
.flaticon-heart-1:before { content: "\f14f"; }
.flaticon-line-2:before { content: "\f150"; }
.flaticon-check-2:before { content: "\f151"; }
.flaticon-clock:before { content: "\f152"; }
.flaticon-cogwheel:before { content: "\f153"; }
.flaticon-shape:before { content: "\f154"; }
.flaticon-music-1:before { content: "\f155"; }
.flaticon-support-1:before { content: "\f156"; }
.flaticon-people:before { content: "\f157"; }
.flaticon-interface-4:before { content: "\f158"; }
.flaticon-interface-3:before { content: "\f159"; }
.flaticon-24-hours-support:before { content: "\f15a"; }
.flaticon-light-bulb-on:before { content: "\f15b"; }
.flaticon-note-1:before { content: "\f15c"; }
.flaticon-technology-2:before { content: "\f15d"; }
.flaticon-interface-2:before { content: "\f15e"; }
.flaticon-interface-1:before { content: "\f15f"; }
.flaticon-cross:before { content: "\f160"; }
.flaticon-equalizer:before { content: "\f161"; }
.flaticon-sports-car:before { content: "\f162"; }
.flaticon-location:before { content: "\f163"; }
.flaticon-eye:before { content: "\f164"; }
.flaticon-lock:before { content: "\f165"; }
.flaticon-arrows-9:before { content: "\f166"; }
.flaticon-arrows-8:before { content: "\f167"; }
.flaticon-security-badge:before { content: "\f168"; }
.flaticon-house-outline:before { content: "\f169"; }
.flaticon-arrows-7:before { content: "\f16a"; }
.flaticon-round:before { content: "\f16b"; }
.flaticon-arrows-6:before { content: "\f16c"; }
.flaticon-check-symbol:before { content: "\f16d"; }
.flaticon-back:before { content: "\f16e"; }
.flaticon-delete:before { content: "\f16f"; }
.flaticon-three-1:before { content: "\f170"; }
.flaticon-line-1:before { content: "\f171"; }
.flaticon-transport:before { content: "\f172"; }
.flaticon-symbol:before { content: "\f173"; }
.flaticon-speech-bubble-with-three-circles-inside:before { content: "\f174"; }
.flaticon-circle-2:before { content: "\f175"; }
.flaticon-circle-1:before { content: "\f176"; }
.flaticon-speech-bubble-outline-of-rectangular-shape:before { content: "\f177"; }
.flaticon-circular-speech-bubble-with-three-dots-inside:before { content: "\f178"; }
.flaticon-circle:before { content: "\f179"; }
.flaticon-smiling-emoticon-square-face:before { content: "\f17a"; }
.flaticon-map:before { content: "\f17b"; }
.flaticon-long-arrow-pointing-to-left:before { content: "\f17c"; }
.flaticon-arrows-5:before { content: "\f17d"; }
.flaticon-briefcase:before { content: "\f17e"; }
.flaticon-bullseye:before { content: "\f17f"; }
.flaticon-spin:before { content: "\f180"; }
.flaticon-cellphone:before { content: "\f181"; }
.flaticon-computer:before { content: "\f182"; }
.flaticon-tool-1:before { content: "\f183"; }
.flaticon-e-mail-envelope:before { content: "\f184"; }
.flaticon-microphone:before { content: "\f185"; }
.flaticon-smartphone-call:before { content: "\f186"; }
.flaticon-interface:before { content: "\f187"; }
.flaticon-levels-adjust:before { content: "\f188"; }
.flaticon-business-2:before { content: "\f189"; }
.flaticon-blocks-with-angled-cuts:before { content: "\f18a"; }
.flaticon-straight-quotes:before { content: "\f18b"; }
.flaticon-cell-phone:before { content: "\f18c"; }
.flaticon-arrows-4:before { content: "\f18d"; }
.flaticon-play-button-2:before { content: "\f18e"; }
.flaticon-calendar-1:before { content: "\f18f"; }
.flaticon-arrows-3:before { content: "\f190"; }
.flaticon-wallet-1:before { content: "\f191"; }
.flaticon-bars:before { content: "\f192"; }
.flaticon-menu-1:before { content: "\f193"; }
.flaticon-arrows-2:before { content: "\f194"; }
.flaticon-anchor:before { content: "\f195"; }
.flaticon-technology-1:before { content: "\f196"; }
.flaticon-money-4:before { content: "\f197"; }
.flaticon-wallet:before { content: "\f198"; }
.flaticon-calendar:before { content: "\f199"; }
.flaticon-shirt:before { content: "\f19a"; }
.flaticon-add:before { content: "\f19b"; }
.flaticon-check-1:before { content: "\f19c"; }
.flaticon-link-1:before { content: "\f19d"; }
.flaticon-check:before { content: "\f19e"; }
.flaticon-time-1:before { content: "\f19f"; }
.flaticon-envelope:before { content: "\f1a0"; }
.flaticon-shopping-bag:before { content: "\f1a1"; }
.flaticon-phone-call:before { content: "\f1a2"; }
.flaticon-book:before { content: "\f1a3"; }
.flaticon-house:before { content: "\f1a4"; }
.flaticon-share:before { content: "\f1a5"; }
.flaticon-avatar:before { content: "\f1a6"; }
.flaticon-tag:before { content: "\f1a7"; }
.flaticon-star:before { content: "\f1a8"; }
.flaticon-link:before { content: "\f1a9"; }
.flaticon-settings-1:before { content: "\f1aa"; }
.flaticon-heart:before { content: "\f1ab"; }
.flaticon-placeholder:before { content: "\f1ac"; }
.flaticon-garbage:before { content: "\f1ad"; }
.flaticon-settings:before { content: "\f1ae"; }
.flaticon-money-3:before { content: "\f1af"; }
.flaticon-money-2:before { content: "\f1b0"; }
.flaticon-headphones:before { content: "\f1b1"; }
.flaticon-coin:before { content: "\f1b2"; }
.flaticon-truck:before { content: "\f1b3"; }
.flaticon-note:before { content: "\f1b4"; }
.flaticon-line:before { content: "\f1b5"; }
.flaticon-checked:before { content: "\f1b6"; }
.flaticon-menu:before { content: "\f1b7"; }
.flaticon-arrows-1:before { content: "\f1b8"; }
.flaticon-arrows:before { content: "\f1b9"; }
.flaticon-stats:before { content: "\f1ba"; }
.flaticon-video-camera:before { content: "\f1bb"; }
.flaticon-social:before { content: "\f1bc"; }
.flaticon-correct:before { content: "\f1bd"; }
.flaticon-visible:before { content: "\f1be"; }
.flaticon-support:before { content: "\f1bf"; }
.flaticon-multimedia:before { content: "\f1c0"; }
.flaticon-rocket-launch:before { content: "\f1c1"; }
.flaticon-search:before { content: "\f1c2"; }
.flaticon-money-1:before { content: "\f1c3"; }
.flaticon-money:before { content: "\f1c4"; }
.flaticon-letter:before { content: "\f1c5"; }
.flaticon-nature:before { content: "\f1c6"; }
.flaticon-smartphone:before { content: "\f1c7"; }
.flaticon-connection:before { content: "\f1c8"; }
.flaticon-food-1:before { content: "\f1c9"; }
.flaticon-technology:before { content: "\f1ca"; }
.flaticon-bullhorn:before { content: "\f1cb"; }
.flaticon-time:before { content: "\f1cc"; }
.flaticon-food:before { content: "\f1cd"; }
.flaticon-home:before { content: "\f1ce"; }
.flaticon-play-button-1:before { content: "\f1cf"; }
.flaticon-volunteer:before { content: "\f1d0"; }
.flaticon-social-media:before { content: "\f1d1"; }
.flaticon-business-1:before { content: "\f1d2"; }
.flaticon-business:before { content: "\f1d3"; }
.flaticon-atom:before { content: "\f1d4"; }
.flaticon-boat-anchor:before { content: "\f1d5"; }
.flaticon-t-shirt-outline:before { content: "\f1d6"; }
.flaticon-three:before { content: "\f1d7"; }
.flaticon-drink-beer-jar:before { content: "\f1d8"; }
.flaticon-play-button:before { content: "\f1d9"; }
.flaticon-school:before { content: "\f1da"; }
.flaticon-atomic-orbitals:before { content: "\f1db"; }
.flaticon-energy-atom:before { content: "\f1dc"; }
.flaticon-sign:before { content: "\f1dd"; }