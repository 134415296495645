/* CharityPro Donation and Fundraisng HTML Template */


/************ TABLE OF CONTENTS ***************

1. Fonts
2. Reset
3. Global
4. Main Header / Style One / Style Two
5. Main Slider
6. Fluid Section
7. Featured Cause Section
8. Recent Causes
9. Call TO Action
10. Upcoming Events
11. Fullwidth Section One
12. Our Projects
13. Testimonials Style One / Style Two
14. News Section
15. Become Volunteer
16. Sponsors Style One
17. Main Footer
18. Team Section
19. Donate Section
20. Causes Style One
21. Causes Style Two
22. Cause Details
23. Projects Boxed View / Fullwidth Gallery
24. Sidebar Page
25. Sidebar
26. Sidebar Widgets
27. Blog Classic
28. Blog Details
29. Contact Us
30. Map Section


**********************************************/

// @import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|PT+Serif:400,400i,700,700i|Crimson+Text:400,400i,600,600i,700,700i|Merriweather:300,300i,400,400i,700,700i');

/*font-family: 'PT Serif', serif;
font-family: 'Montserrat', sans-serif;
font-family: 'Crimson Text', serif;*/


// @import url('font-awesome.css');
// @import url('flaticon.css');
// @import url('animate.css');
// @import url('owl.css');
// @import url('jquery-ui.css');
@import url('jquery.fancybox.css');

/***

====================================================================
	Reset
====================================================================

 ***/
* {
	margin:0px;
	padding:0px;
	border:none;
	outline:none;
}

/***

====================================================================
	Global Settings
====================================================================

 ***/

body {
	font-family: 'Montserrat', sans-serif;
	font-size:16px;
	color:#767676;
	line-height:1.8em;
	font-weight:400;
	background:#ffffff;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
}

a{
	text-decoration:none;
	cursor:pointer;
	color:#fc2c62;
}

a:hover,a:focus,a:visited{
	text-decoration:none;
	outline:none;
}

h1,h2,h3,h4,h5,h6 {
	position:relative;
	font-family: 'Montserrat', sans-serif;
	font-weight:normal;
	margin:0px;
	background:none;
	line-height:1.5em;
}

input,button,select,textarea{
	font-family: 'Montserrat', sans-serif;
}

p{
	position:relative;
	line-height:1.8em;
}

.strike-through{
	text-decoration:line-through;
}

.auto-container{
	position:static;
	max-width:1200px;
	padding:0px 15px;
	margin:0 auto;
}

.medium-container{
	max-width:850px;
}

.page-wrapper{
	position:relative;
	margin:0 auto;
	width:100%;
	min-width:300px;

}

ul,li{
	list-style:none;
	padding:0px;
	margin:0px;
}

.theme-btn{
	display:inline-block;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.centered{
	text-align:center;
}

.btn-style-one{
	position:relative;
	font-family: 'Montserrat', sans-serif;
	padding:10px 35px;
	line-height:28px;
	text-transform:uppercase;
	background:#fc2c62;
	color:#ffffff;
	letter-spacing:0px;
	border:2px solid #fc2c62;
	font-size:14px;
	font-weight:600;
	border-radius:26px;
}

.btn-style-one:hover{
	background:none;
	color:#fc2c62;
}

.btn-style-two{
	position:relative;
	font-family: 'Montserrat', sans-serif;
	padding:10px 35px;
	line-height:28px;
	text-transform:uppercase;
	background:none;
	color:#ffffff;
	letter-spacing:0px;
	border:2px solid #ffffff;
	font-size:14px;
	font-weight:600;
	border-radius:26px;
}

.btn-style-two:hover{
	border-color:#fc2c62;
	color:#fc2c62;
}

.btn-style-three{
	position:relative;
	font-family: 'Montserrat', sans-serif;
	padding:9px 30px;
	line-height:24px;
	text-transform:uppercase;
	background:none;
	color:#2a2d32;
	letter-spacing:0px;
	border:2px solid #e0e0e0;
	font-size:14px;
	font-weight:600;
	border-radius:23px;
}

.btn-style-three:hover{
	border-color:#fc2c62;
	color:#fc2c62;
}

.btn-style-four{
	position:relative;
	font-family: 'Montserrat', sans-serif;
	padding:9px 30px;
	line-height:28px;
	text-transform:uppercase;
	background:#fc2c62;
	color:#ffffff;
	letter-spacing:0px;
	border:2px solid #fc2c62;
	font-size:14px;
	font-weight:600;
	border-radius:25px;
}

.btn-style-four:hover{
	background:none;
	color:#fc2c62;
}

.btn-style-five{
	position:relative;
	font-family: 'Montserrat', sans-serif;
	padding:9px 30px;
	line-height:28px;
	text-transform:uppercase;
	background:none;
	color:#fc2c62;
	letter-spacing:0px;
	border:2px solid #fc2c62;
	font-size:14px;
	font-weight:600;
	border-radius:25px;
}

.btn-style-five:hover{
	background:#fc2c62;
	color:#ffffff;
}

.theme-btn .icon-left{
	padding-right:10px;
}

.theme-btn .icon-right{
	padding-left:10px;
}

.theme_color{
	color:#fc2c62;
}

.light-font{
	font-weight:300;
}

.regular-font{
	font-weight:400;
}

.semibold-font{
	font-weight:600;
}

.bold-font{
	font-weight:700;
}

.ex-bold-font{
	font-weight:800;
}

.heavy-font{
	font-weight:900;
}

.bg-lightgrey{
	background-color:#f5f7f8;
}

*.no-bg,
div.no-bg{
	background:none !important;
}

.text-uppercase{
	text-transform:uppercase;
}

.preloader{ position:fixed; left:0px; top:0px; width:100%; height:100%; z-index:999999; background-color:#ffffff; background-position:center center; background-repeat:no-repeat; background-image:url(../images/icons/preloader.GIF);}

img{
	display:inline-block;
	max-width:100%;
	height:auto;
}

/***

====================================================================
	Scroll To Top style
====================================================================

***/

.scroll-to-top{
	position:fixed;
	bottom:15px;
	right:15px;
	width:40px;
	height:40px;
	color:#ffffff;
	font-size:13px;
	text-transform:uppercase;
	line-height:38px;
	text-align:center;
	z-index:100;
	cursor:pointer;
	border-radius:50%;
	background:#0a0a0a;
	display:none;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.scroll-to-top:hover{
	color:#ffffff;
	background:#fc2c62;
}

/***

====================================================================
	Main Header style
====================================================================

***/

.main-header{
	position:relative;
	left:0px;
	top:0px;
	z-index:999;
	width:100%;
	background:#222730;
	font-family:'Montserrat',sans-serif;
}

.main-header.header-style-two{
	top:10px;
	position:absolute;
	background:none;
}

.sticky-header{
	position:fixed;
	opacity:0;
	visibility:hidden;
	left:0px;
	top:0px;
	width:100%;
	padding:0px 0px;
	background:#ffffff;
	z-index:0;
	border-bottom:1px solid #e0e0e0;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.sticky-header .logo{
	padding:0px 0px;
}

.fixed-header .sticky-header{
	z-index:999;
	opacity:1;
	visibility:visible;
	-ms-animation-name: fadeInDown;
	-moz-animation-name: fadeInDown;
	-op-animation-name: fadeInDown;
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
	-ms-animation-duration: 500ms;
	-moz-animation-duration: 500ms;
	-op-animation-duration: 500ms;
	-webkit-animation-duration: 500ms;
	animation-duration: 500ms;
	-ms-animation-timing-function: linear;
	-moz-animation-timing-function: linear;
	-op-animation-timing-function: linear;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-ms-animation-iteration-count: 1;
	-moz-animation-iteration-count: 1;
	-op-animation-iteration-count: 1;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}

.main-header .auto-container{
	position:relative;
}

.main-header .header-upper{
	position:relative;
}

.main-header .header-upper .logo-box{
	position:relative;
	float:left;
	padding:22px 0px;
	z-index:10;
}

.main-header .header-upper .logo-box .logo img{
	display:inline-block;
	max-width:100%;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-header .header-upper .upper-right{
	position:relative;
	float:right;
	padding:37px 0px 0px;
}

.main-header .header-upper .info-box{
	position:relative;
	float:left;
	margin-left:40px;
	padding-left:50px;
	min-height:50px;
}

.main-header .header-upper .info-box .title{
	font-size:18px;
	color:#ffffff;
	line-height:30px;
	font-weight:600;
}

.main-header .header-upper .info-box .text{
	font-size:15px;
	color:#6f747c;
	line-height:20px;
}

.main-header .header-upper .info-box .icon{
	position:absolute;
	left:0px;
	top:0px;
	line-height:50px;
	font-size:32px;
	color:#ffffff;
}

.main-header .header-upper .social{
	padding-left:0px;
	padding:5px 0px;
}

.main-header .header-upper .social .social-links li{
	position:relative;
	float:left;
	margin-left:7px;
}

.main-header .header-upper .social .social-links li:first-child{
	margin-left:0px;
}

.main-header .header-upper .social .social-links li a{
	position:relative;
	display:block;
	width:40px;
	height:40px;
	line-height:40px;
	text-align:center;
	color:#ffffff;
	font-size:16px;
	border-radius:20px;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.main-header .header-upper .social .social-links li a:hover{
	opacity:0.70;
}

.main-header .header-upper .social .social-links li .facebook{
	background:#1770c3;
}

.main-header .header-upper .social .social-links li .twitter{
	background:#00b0ea;
}

.main-header .header-upper .social .social-links li .google-plus{
	background:#ff4a46;
}

.main-header .header-upper .social .social-links li .linkedin{
	background:#32608a;
}

.main-header .header-lower{
	position:relative;
	padding:0px 0px;
	left:0px;
	top:0px;
	width:100%;
	background:#242933;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-header .nav-outer{
	position:relative;
	padding-right:190px;
}

.header-style-two .nav-outer{
	float:right;
	margin-top:40px;
}

.main-header .nav-outer .donate-btn{
	position:absolute;
	right:0px;
	top:0px;
	line-height:30px;
	padding:17px 40px;
	background:#fc2c62;
	color:#ffffff;
	text-align:center;
	font-size:16px;
	font-weight:600;
	text-transform:uppercase;
}

.header-style-two .nav-outer .donate-btn{
	top:2px;
	line-height:24px;
	font-size:14px;
	padding:13px 15px;
	width:170px;
	text-align:center;
	border-radius:25px;
}

.main-menu{
	position:relative;
	font-family:'Montserrat',sans-serif;
}

.main-menu .navbar-collapse{
	padding:0px;
}

.main-menu .navigation{
	position:relative;
	margin:0px;
}

.main-menu .navigation > li{
	position:relative;
	display:inline-block;
	float:left;
	padding:0px 0px;
}

.header-style-two .main-menu .navigation > li{
	margin-right:24px;
}

.main-menu .navigation > li > a{
	position:relative;
	display:block;
	padding:20px 20px 20px 15px;
	color:#ffffff;
	text-align:center;
	line-height:24px;
	letter-spacing:0.01em;
	text-transform:uppercase;
	font-weight:500;
	opacity:1;
	font-size:14px;
	transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
}

.header-style-two .main-menu .navigation > li > a{
	padding:20px 0px;
}

.main-menu .navigation > li.dropdown > a{
	padding-right:30px;
}

.header-style-two .main-menu .navigation > li.dropdown > a{
	padding-right:15px;
}

.main-menu .navigation > li.dropdown > a:after{
	font-family: 'FontAwesome';
	content: "\f107";
	position:absolute;
	right:10px;
	top:20px;
	width:10px;
	height:24px;
	display:block;
	line-height:24px;
	font-size:16px;
	font-weight:normal;
	text-align:center;
	z-index:5;
}

.header-style-two .main-menu .navigation > li.dropdown > a:after{
	right:0px;
}

.sticky-header .main-menu .navigation > li > a{
	padding:13px 15px !important;
	font-size:14px;
	color:#333333 !important;
}

.sticky-header .main-menu .navigation > li.dropdown > a:after{
	display:none;
}

.main-menu .navigation > li:hover > a,
.main-menu .navigation > li.current > a,
.main-menu .navigation > li.current-menu-item > a{
	background:#fc2c62;
	opacity:1;
}

.header-style-two .main-menu .navigation > li:hover > a,
.header-style-two .main-menu .navigation > li.current > a,
.header-style-two .main-menu .navigation > li.current-menu-item > a{
	background:none;
	color:#fc2c62;
}

.sticky-header .main-menu .navigation > li:hover > a,
.sticky-header .main-menu .navigation > li.current > a,
.sticky-header .main-menu .navigation > li.current-menu-item > a{
	color:#ffffff !important;
	background:#fc2c62;
}

.main-menu .navigation > li > ul{
	position:absolute;
	left:0px;
	top:100%;
	width:220px;
	padding:0px;
	z-index:100;
	display:none;
	background:#ffffff;
	border-bottom:3px solid #fc2c62;
	-webkit-transform:scaleY(0);
	-ms-transform:scaleY(0);
	-o-transform:scaleY(0);
	-moz-transform:scaleY(0);
	transform:scaleY(0);
	-webkit-transform-origin:top;
	-ms-transform-origin:top;
	-o-transform-origin:top;
	-moz-transform-origin:top;
	transform-origin:top;
	-webkit-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-ms-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-o-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-moz-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
}

.light-version .main-box .main-menu .navigation > li > ul{
	margin-top:-20px;
}

.main-menu .navigation > li > ul.from-right{
	left:auto;
	right:0px;
}

.main-menu .navigation > li.dropdown:hover > ul{
	visibility:visible;
	opacity:1;
	top:100%;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-webkit-transform:scaleY(1);
	-ms-transform:scaleY(1);
	-o-transform:scaleY(1);
	-moz-transform:scaleY(1);
	transform:scaleY(1);
}

.main-menu .navigation > li > ul > li{
	position:relative;
	border-bottom:1px solid rgba(0,0,0,0.10);
}

.main-menu .navigation > li > ul > li:last-child{
	border-bottom:none;
}

.main-menu .navigation > li > ul > li > a{
	position:relative;
	display:block;
	padding:12px 20px 12px 40px;
	line-height:24px;
	font-weight:400;
	font-size:14px;
	text-transform:capitalize;
	color:#242424;
	transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
}

.main-menu .navigation > li > ul > li > a:before{
	font-family: 'FontAwesome';
	content: "\f10c";
	position:absolute;
	left:20px;
	top:12px;
	width:10px;
	height:24px;
	display:block;
	line-height:24px;
	font-size:10px;
	font-weight:normal;
	z-index:5;
}

.main-menu .navigation > li > ul > li > a:after{
	font-family: 'FontAwesome';
	content: "\f111";
	position:absolute;
	left:23px;
	top:12px;
	width:10px;
	height:24px;
	display:block;
	line-height:24px;
	font-size:3px;
	font-weight:normal;
	z-index:5;
}

.main-menu .navigation > li > ul > li:hover > a{
	color:#fc2c62;
	background:#f9f9f9;
}

/*.main-menu .navigation > li > ul > li.dropdown > a:after{
	font-family: 'FontAwesome';
	content: "\f105";
	position:absolute;
	right:10px;
	top:15px;
	width:10px;
	height:24px;
	display:block;
	line-height:24px;
	font-size:16px;
	font-weight:normal;
	text-align:center;
	z-index:5;
}*/

.main-menu .navigation > li > ul > li > ul{
	position:absolute;
	left:100%;
	top:0px;
	width:220px;
	padding:0px;
	z-index:100;
	display:none;
	background:#ffffff;
	border-top:3px solid #fc2c62;
	-webkit-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-ms-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-o-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-moz-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-webkit-transform:scaleY(0);
	-ms-transform:scaleY(0);
	-o-transform:scaleY(0);
	-moz-transform:scaleY(0);
	transform:scaleY(0);
	-webkit-transform-origin:top;
	-ms-transform-origin:top;
	-o-transform-origin:top;
	-moz-transform-origin:top;
	transform-origin:top;
}

.main-menu .navigation li > ul > li.dropdown:hover > ul{
	visibility:visible;
	opacity:1;
	top:0;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-webkit-transform:scaleY(1);
	-ms-transform:scaleY(1);
	-o-transform:scaleY(1);
	-moz-transform:scaleY(1);
	transform:scaleY(1);
}

.main-menu .navigation > li > ul > li > ul > li{
	position:relative;
	border-bottom:1px solid rgba(0,0,0,0.10);
}

.main-menu .navigation > li > ul > li:last-child{
	border-bottom:none;
}

.main-menu .navigation > li > ul > li > ul > li > a{
	position:relative;
	display:block;
	padding:12px 20px 12px 40px;
	line-height:24px;
	font-weight:400;
	font-size:14px;
	text-transform:capitalize;
	color:#242424;
	transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
}

.main-menu .navigation > li > ul > li > ul > li > a:before{
	font-family: 'FontAwesome';
	content: "\f10c";
	position:absolute;
	left:20px;
	top:12px;
	width:10px;
	height:24px;
	display:block;
	line-height:24px;
	font-size:10px;
	font-weight:normal;
	z-index:5;
}

.main-menu .navigation > li > ul > li > ul > li > a:after{
	font-family: 'FontAwesome';
	content: "\f111";
	position:absolute;
	left:23px;
	top:12px;
	width:10px;
	height:24px;
	display:block;
	line-height:24px;
	font-size:3px;
	font-weight:normal;
	z-index:5;
}

.main-menu .navigation > li > ul > li > ul > li:hover > a{
	color:#fc2c62;
	background:#f9f9f9;
}

.main-menu .navbar-collapse > ul li.dropdown .dropdown-btn{
	position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 100%;
  text-align: center;
  font-size: 20px;
  line-height: 42px;
  color: #1393f7;
	background:#161617;
  cursor: pointer;
  z-index: 5;
  display: none;
}

.sticky-header .main-menu .navigation > li{
	float:left;
	padding:0px;
	margin:0px;
	display:block;
}

/***

====================================================================
	Gallery Popup Settings
====================================================================

***/


.fancybox-next span,
.fancybox-prev span{
	background-image:none !important;
	width:44px !important;
	height:44px !important;
	line-height:44px !important;
	text-align:center;
}

.fancybox-next span:before,
.fancybox-prev span:before{
	content:'';
	position:absolute;
	font-family: 'FontAwesome';
	left:0px;
	top:0px;
	font-size:12px;
	width:44px !important;
	height:44px !important;
	line-height:44px !important;
	background-color:rgba(28,28,28,0.40) !important;
	color:#ffffff;
	visibility:visible;
	transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
}

.fancybox-next span:before{
	content:'\f178';
}

.fancybox-prev span:before{
	content:'\f177';
}

.fancybox-next:hover span:before,
.fancybox-prev:hover span:before{
	background-color:#fc2c62 !important;
	color:#ffffff;
}

.fancybox-type-image .fancybox-close{
	right:0px;
	top:0px;
	width:45px;
	height:45px;
	background:url(../images/icons/icon-cross.png) center center no-repeat;
	background-color:rgba(17,17,17,0.50) !important;
}

.fancybox-type-image .fancybox-close:hover{
	background-color:#000000 !important;
}

.fancybox-type-image .fancybox-skin{
	padding:0px !important;
}

/***

====================================================================
	Fluid Section One
====================================================================

***/

.fluid-section-one{
	position:relative;
	background:#f6285d;
	color:#ffffff;
}

.fluid-section-one .outer{
	position:relative;
}

.fluid-section-one .fluid-column{
	position:relative;
	float:left;
	width:33.333%;
	padding:40px 30px;
	text-align:center;
}

.fluid-section-one .fluid-column:nth-child(1){
	background:#fc2c62;
}

.fluid-section-one .fluid-column:nth-child(2){
	background:#f6285d;
}

.fluid-section-one .fluid-column:nth-child(3){
	background:#ef2055;
}

.fluid-section-one .fluid-column .inner{
	position:relative;
	display:inline-block;
	padding-left:90px;
	text-align:left;
}

.fluid-section-one .fluid-column .inner .icon-box{
	position:absolute;
	left:0px;
	top:0px;
	font-size:64px;
	opacity:0.20;
	color:#ffffff;
	line-height:66px;
	transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
}

.fluid-section-one .fluid-column:hover .inner .icon-box{
	opacity:0.50;
}

.fluid-section-one .fluid-column .inner h3{
	font-size:15px;
	font-weight:500;
	text-transform:uppercase;
	line-height:1.2em;
}

.fluid-section-one .fluid-column .inner h2{
	font-size:40px;
	font-weight:400;
	font-family: 'Crimson Text', serif;
	text-transform:capitalize;
	line-height:1.2em;
}

.fluid-section-one .fluid-column .overlay-link{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	z-index:1;
	background:rgba(0,0,0,0);
	transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
}

.fluid-section-one .fluid-column .overlay-link:hover{
	background:rgba(255,255,255,0.10);
}

/***

====================================================================
	Featured Cause Section
====================================================================

***/

.featured-cause-section{
	position:relative;
	padding:100px 0px 70px;
}

.featured-cause-section .title{
	max-width:1000px;
	text-align:center;
	margin:0 auto 70px;
}

.featured-cause-section .title h2{
	font-size:40px;
	line-height:1.3em;
	font-weight:700;
	color:#292929;
}

.featured-cause-section .image-column,
.featured-cause-section .content-column{
	position:relative;
	margin-bottom:30px;
}

.featured-cause-section .image-column img{
	display:block;
	width:100%;
}

.featured-cause-section .content-column .inner{
	position:relative;
	padding-left:30px;
}

.featured-cause-section .content-column .cause-cat{
	position:relative;
	margin-bottom:15px;
}

.featured-cause-section .content-column .cause-cat a{
	display:inline-block;
	font-size:14px;
	font-weight:500;
	line-height:24px;
	padding:8px 20px;
	color:#b2b2b2;
	background:#f5f5f5;
	margin-right:5px;
	text-transform:uppercase;
	border-radius:3px;
}

.featured-cause-section .content-column h3{
	font-size:28px;
	font-weight:700;
	color:#2a2a2a;
	margin-bottom:15px;
	line-height:1.4em;
}

.featured-cause-section .content-column h3 a{
	color:#2a2a2a;
}

.featured-cause-section .content-column .desc{
	position:relative;
	font-family:'PT Serif',serif;
	line-height:1.8em;
	margin-bottom:40px;
}

.featured-cause-section .donate-box{
	position:relative;
	margin-bottom:50px;
}

.featured-cause-section .donate-box .donate-bar{
	position:relative;
	width:100%;
	height:14px;
	margin-bottom:5px;
}

.featured-cause-section .donate-box .donate-bar .bar-inner{
	position:relative;
	width:100%;
	height:14px;
	background:#f7f7f7;
	border-radius:7px;
}

.featured-cause-section .donate-box .donate-bar .bar-inner .bar{
	position:absolute;
	left:0%;
	top:0px;
	height:14px;
	width:0px;
	background:#fc2c62 url(../images/icons/progress-bar-fill.jpg) left center repeat-x;
	border-radius:7px;
	-webkit-transition:all 2000ms ease;
	-ms-transition:all 2000ms ease;
	-o-transition:all 2000ms ease;
	-moz-transition:all 2000ms ease;
	transition:all 2000ms ease;
}

.featured-cause-section .donate-box .donate-info{
	position:relative;
	color:#c9c9c9;
	line-height:24px;
	margin-bottom:15px;
}

.featured-cause-section .donate-box .donate-info strong{
	text-transform:uppercase;
	font-weight:700;
	color:#363636;
}

.featured-cause-section .donate-box .donate-info .goal{
	color:#ff4d49;
}

.featured-cause-section .donate-box .count-box{
	position:absolute;
	right:0px;
	top:-50px;
	display:block;
	height:30px;
	min-width:50px;
	padding:0px 10px;
	line-height:30px;
	background:#2a2d32;
	font-size:13px;
	font-weight:400;
	color:#ffffff;
	text-align:center;
	border-radius:3px;
}

.featured-cause-section .donate-box .count-box:after{
	content:'';
	position:absolute;
	left:50%;
	margin-left:-5px;
	top:100%;
	border:5px solid transparent;
	border-top:5px solid #2a2d32;
}

/***

====================================================================
	Recent Causes Section
====================================================================

***/

.recent-causes-section{
	position:relative;
	padding:100px 0px 50px;
}

.sec-title{
	position:relative;
	margin-bottom:50px;
}

.sec-title.centered{
	text-align:center;
}

.sec-title .subtitle{
	position:relative;
	display:inline-block;
	color:#bebebe;
	line-height:24px;
	padding:3px 15px;
	font-size:14px;
	text-transform:uppercase;
	font-weight:500;
	margin-bottom:10px;
	background:#f6f6f6;
	border-radius:3px;
}

.sec-title h2,
.sec-title h3,
.sec-title p.title{
	position:relative;
	color:#292929;
	font-weight:700;
	font-size:38px;
	/*text-transform:capitalize;*/
	line-height:1.3em;
	margin-bottom: 0;
}

.sec-title.centered h2,
.sec-title.centered h3{
	max-width:1024px;
	margin:0 auto;
}

.sec-title .desc-text{
	position:relative;
	color:#626262;
	font-size:18px;
	font-weight:400;
	line-height:1.8em;
	padding-top:15px;
}

.sec-title.centered .desc-text{
	max-width:780px;
	margin:0 auto;
}

.sec-title .link-box a{
	display:inline-block;
	padding-top:7px;
	color:#363636;
	font-weight:600;
	text-transform:uppercase;
	font-size:14px;
	border-bottom:2px solid #ff4a46;
}

.default-cause-box{
	position:relative;
	margin-bottom:50px;
}

.owl-theme .default-cause-box{
	position:relative;
	margin-bottom:0px;
}

.default-cause-box .inner-box{
	position:relative;
	display:block;
	background:#ffffff;
	border:1px solid #e9e9e9;
	-webkit-box-shadow:0px 0px 5px 0px rgba(0,0,0,0.10);
	-ms-box-shadow:0px 0px 5px 0px rgba(0,0,0,0.10);
	-o-box-shadow:0px 0px 5px 0px rgba(0,0,0,0.10);
	-moz-box-shadow:0px 0px 5px 0px rgba(0,0,0,0.10);
	box-shadow:0px 0px 5px 0px rgba(0,0,0,0.10);
}

.default-cause-box .inner-box .image-column,
.default-cause-box .inner-box .content-column{
	padding:0px;
}

.default-cause-box .inner-box .image-box{
	position:relative;
	display:block;
}

.default-cause-box .inner-box .image-box img{
	position:relative;
	display:block;
	width:100%;
}

.default-cause-box .lower-content{
	position:relative;
	padding:30px;
}

.default-cause-box h3{
	font-size:20px;
	color:#363636;
	font-weight:700;
	margin-bottom:10px;
	line-height:1.4em;
}

.default-cause-box h3 a{
	color:#363636;
}

.default-cause-box h3 a:hover{
	color:#ff4d49;
}

.default-cause-box .lower-content .text{
	position:relative;
	color:#767676;
	line-height:1.8em;
	font-family:'PT Serif',serif;
	margin-bottom:15px;
}

.default-cause-box .donate-box{
	position:relative;
}

.default-cause-box .donate-box .donate-bar{
	position:relative;
	width:100%;
	height:12px;
	margin-bottom:20px;
}

.default-cause-box .donate-box .donate-bar .bar-inner{
	position:relative;
	width:100%;
	height:12px;
	background:#f7f7f7;
	border-radius:6px;
}

.default-cause-box .donate-box .donate-bar .bar-inner .bar{
	position:absolute;
	left:0%;
	top:0px;
	height:12px;
	width:0px;
	background:#fc2c62 url(../images/icons/progress-bar-fill.jpg) left center repeat-x;
	border-radius:6px;
	-webkit-transition:all 2000ms ease;
	-ms-transition:all 2000ms ease;
	-o-transition:all 2000ms ease;
	-moz-transition:all 2000ms ease;
	transition:all 2000ms ease;
}

.default-cause-box .donate-box .donate-info{
	position:relative;
	color:#c9c9c9;
	line-height:24px;
	font-size:14px;
}

.default-cause-box .donate-box .donate-info strong{
	text-transform:uppercase;
	font-weight:700;
	font-size:13px;
	color:#363636;
}

.default-cause-box .donate-box .donate-info .goal{
	color:#ff4d49;
}

.cause-box-two{
	position:relative;
	margin-bottom:50px;
}

.cause-box-two .inner-box{
	position:relative;
	display:block;
	background:#ffffff;
	text-align:center;
	border:1px solid #e5e5e5;
}

.cause-box-two .inner-box .image-box{
	position:relative;
	display:block;
}

.cause-box-two .inner-box .image-box img{
	position:relative;
	display:block;
	width:100%;
}

.cause-box-two .lower-content{
	position:relative;
	padding:65px 40px 30px;
}

.cause-box-two .lower-content .progress-box{
	position:absolute;
	left:0px;
	top:-35px;
	z-index:1;
	width:100%;
	height:70px;
}

.cause-box-two .lower-content .progress-box .graph-outer{
	position: relative;
    width: 70px;
	height:70px;
    margin: 0 auto;
    padding: 0px;
    border-radius: 50%;
	background-color: #e0e0e0;
}

.cause-box-two .lower-content .progress-box .graph-outer:before{
	position:absolute;
	content:'';
    left: 5px;
    top: 5px;
    width:60px;
	height:60px;
	z-index:0;
	border-radius:50%;
	background-color:#f6f6f6;
}

.cause-box-two .lower-content .progress-box .inner-text{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	display:block;
	padding-top:20px;
	line-height:35px;
	font-size:16px;
	color:#363636;
	font-weight:700;
}

.cause-box-two h3{
	font-size:20px;
	color:#363636;
	font-weight:700;
	margin-bottom:10px;
	line-height:1.4em;
}

.cause-box-two h3 a{
	color:#363636;
}

.cause-box-two h3 a:hover{
	color:#ff4d49;
}

.cause-box-two .lower-content .text{
	position:relative;
	color:#767676;
	line-height:1.8em;
	margin-bottom:15px;
	font-family:'PT Serif',serif;
}

.cause-box-two .donate-info{
	position:relative;
	color:#c9c9c9;
	line-height:24px;
	font-size:14px;
}

.cause-box-two .donate-info strong{
	text-transform:uppercase;
	font-weight:700;
	font-size:13px;
	color:#363636;
}

.cause-box-two .donate-info .goal{
	color:#ff4d49;
}

.cause-box-two .links{
	position:relative;
	display:table;
	width:100%;
	vertical-align:middle;
}

.cause-box-two .links li{
	position:relative;
	display:table-cell;
	width:50%;
	vertical-align:middle;
}

.cause-box-two .links li a{
	position:relative;
	display:block;
	line-height:30px;
	padding:11px 10px;
	font-size:14px;
	font-weight:600;
	text-transform:uppercase;
}

.cause-box-two .links li a.donate-btn{
	color:#ffffff;
	background:#fc2c62;
}

.cause-box-two .links li a.more-btn{
	color:#292929;
	background:#f5f7f8;
}

.cause-box-two .links li a.donate-btn:hover,
.cause-box-two .links li a.more-btn:hover{
	background:#292929;
	color:#ffffff;
}

/***

====================================================================
	Call TO Action
====================================================================

***/

.call-to-action{
	position:relative;
	padding:90px 0px;
	color:#ffffff;
	text-align:center;
	background-position:center center;
	background-repeat:no-repeat;
	background-size:cover;
	background-attachment:fixed;
}

.call-to-action:before{
	content:'';
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	background:rgba(0,0,0,0.60);
}

.call-to-action .auto-container{
	position:relative;
	z-index:1;
}

.call-to-action h2{
	font-size:36px;
	line-height:1.4em;
	margin-bottom:15px;
	font-family:'PT Serif',serif;
}

.call-to-action h1{
	font-size:72px;
	font-weight:700;
	text-transform:capitalize;
	line-height:1.4em;
	margin-bottom:30px;
}

/***

====================================================================
	Upcoming Events Section
====================================================================

***/

.upcoming-events{
	position:relative;
	padding:100px 0px 70px;
}


.default-event-box{
	position:relative;
	margin-bottom:30px;
}

.default-event-box .inner-box{
	position:relative;
	display:block;
}

.default-event-box .inner-box .image-box{
	position:relative;
	display:block;
}

.default-event-box .inner-box .image-box img{
	position:relative;
	display:block;
	width:100%;
}

.default-event-box .lower-content{
	position:relative;
	padding:30px 0px 0px;
}

.default-event-box .post-meta{
	position:relative;
	font-family:'PT Serif',serif;
	font-style:italic;
	color:#a1a1a1;
	font-size:18px;
	margin-bottom:10px;
}

.default-event-box .post-meta .location{
	color:#ff5858;
}

.default-event-box h3{
	font-size:22px;
	color:#242424;
	font-weight:700;
	line-height:1.4em;
}

.default-event-box h3 a{
	color:#242424;
}

.default-event-box h3 a:hover{
	color:#ff4d49;
}

.default-event-box .lower-content .text{
	position:relative;
	color:#767676;
	line-height:1.8em;
	margin-bottom:15px;
}

.event-style-two{
	position:relative;
	margin-bottom:30px;
}

.column .event-style-two{
	padding-right:10px;
}

.event-style-two .inner-box{
	position:relative;
	display:block;
}

.event-style-two .inner-box .image-box{
	position:relative;
	display:block;
}

.event-style-two .inner-box .image-box img{
	position:relative;
	display:block;
	width:100%;
}

.event-style-two .over-content{
	position:absolute;
	left:30px;
	right:30px;
	bottom:30px;
	padding:25px 30px;
	background:rgba(255,255,255,0.85);
}

.event-style-two .post-meta{
	position:relative;
	font-family:'PT Serif',serif;
	font-style:italic;
	color:#a1a1a1;
	font-size:18px;
	margin-bottom:10px;
}

.event-style-two .post-meta .location{
	color:#ff5858;
}

.event-style-two h3{
	font-size:22px;
	color:#242424;
	font-weight:700;
	line-height:1.4em;
}

.event-style-two h3 a{
	color:#242424;
}

.event-style-two h3 a:hover{
	color:#ff4d49;
}

.event-style-two .over-content .text{
	position:relative;
	color:#767676;
	line-height:1.8em;
	margin-bottom:15px;
}

.event-style-three{
	position:relative;
	margin-bottom:30px;
}

.event-style-three .inner-box{
	position:relative;
	display:block;
	padding-left:195px;
	min-height:140px;
}

.event-style-three .inner-box .image-box{
	position:absolute;
	display:block;
	left:0px;
	top:0px;
	width:165px;
}

.event-style-three .inner-box .image-box img{
	position:relative;
	display:block;
	width:100%;
}

.event-style-three .post-meta{
	position:relative;
	font-family:'PT Serif',serif;
	font-style:italic;
	color:#a1a1a1;
	font-size:16px;
	margin-bottom:10px;
}

.event-style-three .post-meta .location{
	color:#ff5858;
}

.event-style-three h3{
	font-size:20px;
	color:#242424;
	font-weight:700;
	line-height:1.4em;
	margin-bottom:15px;
}

.event-style-three h3 a{
	color:#242424;
}

.event-style-three h3 a:hover{
	color:#ff4d49;
}

.event-style-three .people-status{
	position:relative;
	color:#767676;
	line-height:1.7em;
	font-size:14px;
}

.event-style-three .people-status strong{
	font-weight:600;
	color:#242424;
}

/***

====================================================================
	Fullwidth Section One
====================================================================

***/

.fullwidth-section-one{
	position:relative;
	background:#f9f9f9;
}

.fullwidth-section-one .image-column{
	position:absolute;
	left:0px;
	top:0px;
	float:left;
	width:50%;
	height:100%;
	background-position:center center;
	background-repeat:no-repeat;
	background-size:cover;
}

.fullwidth-section-one .image-column .image{
	position:relative;
	width:100%;
	display:none;
}

.fullwidth-section-one .image-column .image img{
	position:relative;
	width:100%;
	display:block;
}

.fullwidth-section-one .content-column{
	position:relative;
	float:right;
	width:50%;
}

.fullwidth-section-one .content-column .inner{
	position:relative;
	width:100%;
	max-width:600px;
	padding:70px 15px 70px 70px;
}

.fullwidth-section-one .content-column h2,
.fullwidth-section-one .content-column h1{
	font-size:38px;
	font-weight:700;
	color:#292929;
	line-height: 1.3em;
	margin-bottom: 20px;
}

.fullwidth-section-one .content-column h3{
	font-size:24px;
	font-weight:300;
	color:#292929;
	margin-bottom:25px;
}

.fullwidth-section-one .content-column .text-content{
	position:relative;
	line-height:1.8em;
	font-family:'PT Serif',serif;
	margin-bottom:30px;
}

.fullwidth-section-one .content-column .pay-block{
	position:relative;
	margin-bottom:50px;
	text-align:center;
}

.fullwidth-section-one .content-column .pay-block .link-box{
	position:relative;
	display:block;
	color:#292929;
	font-size:18px;
	font-family:'PT Serif',serif;
	font-style:italic;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	transition:all 500ms ease;
}

.fullwidth-section-one .content-column .pay-block .icon-box{
	position:relative;
	display:block;
	width:100px;
	height:100px;
	margin:0 auto 15px;
	font-style:normal;
	color:#fc2c62;
	font-size:42px;
	line-height:96px;
	border:2px solid #e9e9e9;
	background:none;
	border-radius:50%;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	transition:all 500ms ease;
}

.fullwidth-section-one .content-column .pay-block .link-box:hover .icon-box{
	color:#ffffff;
	background:#fc2c62;
	border-color:#fc2c62;
}

.fullwidth-section-one .content-column .pay-block .link-box:hover{
	color:#fc2c62;
}

.fullwidth-section-one .content-column .read-more{
	position:relative;
	display:inline-block;
	color:#363636;
	font-weight:600;
	text-transform:uppercase;
	font-size:14px;
	border-bottom:2px solid #ff4a46;
}

/***

====================================================================
	Projects Section
====================================================================

***/

.projects-section{
	position:relative;
	padding:100px 0px 70px;
}

.projects-section .load-more{
	position:relative;
	padding:30px 0px;
	text-align:center;
}

.default-portfolio-item{
	position:relative;
	margin-bottom:30px;
}

.default-portfolio-item .inner-box{
	position:relative;
	display:block;
	overflow:hidden;
}

.default-portfolio-item .image-box{
	position:relative;
}

.default-portfolio-item .image-box:before{
	content:'';
	position:absolute;
	left:0px;
	bottom:0px;
	width:100%;
	height:40%;
	z-index:1;
	background:rgba(0,0,0,0.50);
	background:-webkit-linear-gradient(top,rgba(0,0,0,0),rgba(0,0,0,0.80));
	background:-ms-linear-gradient(top,rgba(0,0,0,0),rgba(0,0,0,0.80));
	background:-o-linear-gradient(top,rgba(0,0,0,0),rgba(0,0,0,0.80));
	background:-moz-linear-gradient(top,rgba(0,0,0,0),rgba(0,0,0,0.80));
}

.default-portfolio-item .image-box img{
	position:relative;
	display:block;
	width:100%;
}

.default-portfolio-item .title{
	position:absolute;
	left:0px;
	bottom:0px;
	width:100%;
	padding:30px 40px;
	font-size:22px;
	color:#ffffff;
	font-weight:700;
	line-height:1.3em;
	z-index:2;
	-webkit-transition:all 700ms ease;
	-ms-transition:all 700ms ease;
	-o-transition:all 700ms ease;
	-moz-transition:all 700ms ease;
	transition:all 700ms ease;
}

.default-portfolio-item .inner-box:hover .title{
	opacity:0;
	visibility:hidden;
}

.default-portfolio-item .title a{
	color:#ffffff;
}

.default-portfolio-item .overlay-box{
	position:absolute;
	left:0px;
	top:-100%;
	width:100%;
	height:100%;
	color:#ffffff;
	text-align:center;
	background:rgba(252,44,98,0.85);
	z-index:3;
	opacity:0;
	-webkit-transition:all 700ms ease;
	-ms-transition:all 700ms ease;
	-o-transition:all 700ms ease;
	-moz-transition:all 700ms ease;
	transition:all 700ms ease;
}

.default-portfolio-item .inner-box:hover .overlay-box{
	top:0px;
	opacity:1;
}

.default-portfolio-item .overlay-box .overlay-inner{
	position:absolute;
	left:0px;
	bottom:0px;
	width:100%;
	height:100%;
	display:table;
	vertical-align:middle;
}

.default-portfolio-item .overlay-box .content{
	position:relative;
	display:table-cell;
	vertical-align:middle;
	padding:20px 40px;
}

.default-portfolio-item .overlay-box h3{
	font-size:22px;
	color:#ffffff;
	font-weight:700;
	line-height:1.4em;
	margin-bottom:20px;
}

.default-portfolio-item .overlay-box h3 a{
	color:#ffffff;
}

.default-portfolio-item .overlay-box .more-link{
	position:relative;
	display:inline-block;
	width:44px;
	height:44px;
	line-height:40px;
	font-size:18px;
	color:#ffffff;
	border:2px solid #ffffff;
	text-align:center;
	border-radius:50%;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	transition:all 500ms ease;
}

.default-portfolio-item .overlay-box .more-link:hover{
	background:#ffffff;
	color:#fc2c62;
}

/***

====================================================================
	Testimonials One
====================================================================

***/

.testimonial-style-one{
	position:relative;
	padding:100px 0px 120px;
	background-position:center center;
	background-repeat:no-repeat;
	background-size:cover;
	background-attachment:fixed;
}

.testimonial-style-one:before{
	content:'';
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	background:rgba(0,0,0,0.60);
}

.testimonial-style-one .auto-container{
	position:relative;
	z-index:1;
}

.testimonial-style-one .sec-title{
	margin-bottom:60px;
	text-align:center;
}

.testimonial-style-one .sec-title h2{
	color:#ffffff;
}

.testimonial-style-one .slide-item{
	position:relative;
}

.testimonial-style-one .slide-item .inner{
	position:relative;
	display:block;
	background:#ffffff;
	border-radius:5px;
}

.testimonial-style-one .slide-item .upper-content{
	position:relative;
	padding:40px;
	border-bottom:1px solid #e9e9e9;
}

.testimonial-style-one .slide-item .upper-content .text{
	color:#000000;
	font-size:16px;
	font-family:'Merriweather',serif;
	margin-bottom:25px;
}

.testimonial-style-one .slide-item .upper-content .rating{
	position:relative;
	font-weight:300;
}

.testimonial-style-one .slide-item .upper-content .rating strong{
	color:#2b2b2b;
	font-weight:500;
}

.testimonial-style-one .slide-item .upper-content .fa{
	padding-right:7px;
	color:#fc2c62;
}

.testimonial-style-one .slide-item .info{
	position:relative;
	padding:25px 40px;
}

.testimonial-style-one .slide-item .info .author-thumb{
	position:absolute;
	right:35px;
	top:-36px;
	width:70px;
	height:70px;
}

.testimonial-style-one .slide-item .info h4{
	font-size:18px;
	font-weight:600;
	color:#2b2b2b;
	line-height:1.2em;
}

.testimonial-style-one .slide-item .info .location{
	font-size:14px;
	font-weight:400;
	color:#7d7d7d;
}

.testimonial-style-one .owl-dots{
	display:none;
}

.testimonial-style-one .owl-nav{
	padding:0px;
	margin:0px;
	display:block;
}

.testimonial-style-one .owl-nav .owl-prev,
.testimonial-style-one .owl-nav .owl-next{
	position:absolute;
	display:block;
	top:50%;
	margin-top:-15px;
	width:48px;
	height:48px;
	background:#777777;
	color:#ffffff;
	font-size:24px;
	line-height:48px;
	text-align:center;
	border-radius:50%;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	transition:all 500ms ease;
}

.testimonial-style-one .owl-nav .owl-prev{
	left:-100px;
}

.testimonial-style-one .owl-nav .owl-next{
	right:-100px;
	padding-left:2px;
}

.testimonial-style-one .owl-nav .owl-prev:hover,
.testimonial-style-one .owl-nav .owl-next:hover{
	color:#292929;
	background:#ffffff;
}


/***

====================================================================
	News Section
====================================================================

***/

.news-section{
	position:relative;
	padding:100px 0px 80px;
}

.news-style-one{
	position:relative;
	margin-bottom:25px;
}

.news-style-one .inner-box{
	position:relative;
	display:block;
}

.news-style-one.boxed-style .inner-box{
	padding:45px 10px  40px 40px;
	border:1px solid #e9e9e9;
	border-radius:7px;
}

.left-column .news-style-one.boxed-style .inner-box{
	margin-right:15px;
}

.news-style-one h3{
	font-size:30px;
	color:#292929;
	font-weight:700;
	line-height:1.3em;
	margin-bottom:20px;
}

.right-column .news-style-one h3{
	margin-bottom:10px;
	font-size:28px;
}

.news-style-one h3 a{
	color:#292929;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	transition:all 500ms ease;
}

.news-style-one h3 a:hover{
	color:#fc2c62;
}

.news-style-one .text{
	font-family:'PT Serif',serif;
	line-height:1.8em;
	margin-bottom:20px;
}

.news-style-one .post-meta{
	position:relative;
}

.news-style-one.boxed-style .post-meta{
	margin-bottom:30px;
}

.news-style-one .post-meta ul li{
	position:relative;
	float:left;
	margin-right:25px;
	color:#838d8f;
	font-family:'PT Serif',serif;
}

.news-style-one .post-meta ul li .fa{
	padding-right:4px;
	color:#cdd7d8;
}

.news-style-one .post-meta ul li a{
	position:relative;
	color:#838d8f;
}

.news-style-one .post-meta ul li a:hover,
.news-style-one .post-meta ul li a:hover .fa{
	color:#fc2c62;
}

.news-style-one .image-column img{
	display:inline-block;
}

.news-style-one .image-column{
	position:relative;
	text-align:center;
}

.news-style-one.bordered .inner-box{
	border-bottom:1px solid #e9e9e9;
	padding-bottom:20px;
}

.news-section .news-style-one.bordered:last-child .inner-box{
	border-bottom:none;
	padding-bottom:0px;
}


.news-style-two{
	position:relative;
	margin-bottom:30px;
}

.sidebar-page-container .news-style-two{
	margin-bottom:50px;
}

.news-style-two .inner-box{
	position:relative;
	display:block;
	border:1px solid #e9e9e9;
	border-radius:5px;
	overflow:hidden;
}

.news-style-two .lower-content{
	position:relative;
	padding:30px 30px 0px;
}

.news-style-two .lower-content .post-cat{
	position:relative;
	margin-bottom:15px;
}

.news-style-two .lower-content .post-cat a{
	display:inline-block;
	line-height:20px;
	padding:5px 15px;
	background:#fc2c62;
	color:#ffffff;
	text-transform:uppercase;
	font-size:14px;
	font-weight:600;
	border-radius:3px;
}

.news-style-two .lower-content .post-cat a.water{
	background:#13cb84;
}

.news-style-two .lower-content .post-cat a.donate{
	background:#ffa92e;
}

.news-style-two .lower-content h3{
	font-size:22px;
	color:#292929;
	font-weight:700;
	line-height:1.3em;
	margin-bottom:10px;
}

.news-style-two .lower-content h3 a{
	color:#292929;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	transition:all 500ms ease;
}

.news-style-two .lower-content h3 a:hover{
	color:#fc2c62;
}

.news-style-two .lower-content .text{
	font-family:'PT Serif',serif;
	line-height:1.7em;
	margin-bottom:25px;
}

.news-style-two .post-meta{
	position:relative;
	border-top:1px solid #e9e9e9;
	padding:15px 30px;
}

.news-style-two .post-meta .share-btn{
	position:relative;
	font-size:20px;
	float:right;
}

.news-style-two .post-meta .share-btn a{
	position:relative;
	display:block;
	line-height:24px;
	color:#9f9f9f;
}

.news-style-two .post-meta ul{
	position:relative;
	float:left;
}

.news-style-two .post-meta ul li{
	position:relative;
	float:left;
	margin-right:25px;
	line-height:24px;
	color:#9f9f9f;
}

.news-style-two .post-meta ul li .icon{
	padding-right:5px;
}

.news-style-two .post-meta ul li a{
	position:relative;
	line-height:24px;
	color:#9f9f9f;
}

.news-style-two .post-meta ul li a:hover,
.news-style-two .post-meta .share-btn a:hover{
	color:#fc2c62;
}

.news-style-two .image{
	position:relative;
	display:block;
}

.news-style-two .image img,
.blog-details .row .image-column img{
	display:block;
	width:100%;
}

.blog-details .row .image-column{
	margin-bottom:20px;
}

.blog-details blockquote{
	position:relative;
	border:none;
	border-left:5px solid #fc2c62;
	font-size:18px;
	padding:0px 0px 0px 20px;
	margin:0px 0px 20px;
	line-height:1.6em;
	color:#25292f;
}

.blog-details blockquote p{
	margin-bottom:20px;
	line-height:1.6em;
}

.blog-details blockquote p:last-child{
	margin-bottom:0px;
}


/***

====================================================================
	Become Volunteer
====================================================================

***/

.become-volunteer{
	position:relative;
	padding:100px 0px;
	background-position:center center;
	background-repeat:no-repeat;
	background-size:cover;
	background-attachment:fixed;
}

.become-volunteer:before{
	content:'';
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	background:rgba(0,0,0,0.20);
}

.become-volunteer .auto-container{
	position:relative;
	z-index:1;
}

.become-volunteer .content-box{
	position:relative;
	max-width:420px;
	margin:0 auto;
	background:#ffffff;
	padding:40px 40px 50px;
	text-align:center;
	border-radius:5px;
}

.become-volunteer .content-box h2{
	color:#292929;
	font-size:30px;
	line-height:1.4em;
	margin-bottom:20px;
	font-weight:300;
}

.become-volunteer .content-box h2 strong{
	font-size:48px;
}

.become-volunteer .content-box .text{
	position:relative;
	font-family:'PT Serif',serif;
	margin-bottom:30px;
}

/***

====================================================================
	Sponsors Section
====================================================================

***/

.sponsors-section{
	position:relative;
	padding:70px 0px;
}

.sponsors-section.with-border{
	border-top:1px solid #f0f0f0;
}

.sponsors-section .image{
	display:block;
	text-align:center;
}

.sponsors-section .image img{
	position:relative;
	display:inline-block;
	width:auto;
	max-width:100%;
}

.sponsors-section .owl-nav,
.sponsors-section .owl-dots{
	display:none;
}

/***

====================================================================
	Main Footer
====================================================================

***/

.main-footer{
	position:relative;
	background:#222730;
	color:#ffffff;
}

.main-footer .widgets-section{
	position:relative;
	padding:80px 0px 20px;
}

.main-footer .widgets-section .footer-column{
	margin-bottom:30px;
}

.main-footer .footer-widget{
	position:relative;
}

.main-footer .footer-widget h2{
	position:relative;
	font-size:22px;
	text-transform:capitalize;
	font-weight:700;
	margin-bottom:25px;
	color:#ffffff;
}

.main-footer .about-widget{
	padding-right:30px;
}

.main-footer .about-widget .logo{
	position:relative;
	margin-bottom:10px;
	margin-top:-10px;
}

.main-footer .about-widget .title{
	font-size:28px;
	font-family:'Crimson Text',serif;
	line-height:1.3em;
	font-weight:700;
	margin-bottom:25px;
}

.main-footer .about-widget .title .count{
	font-weight:700;
	font-family:'Montserrat',sans-serif;
	color:#fc2c62;
}

.main-footer .about-widget .text{
	position:relative;
	line-height:1.8em;
	font-size:15px;
	font-family:'PT Serif',serif;
}

.main-footer .links-widget .list li{
	position:relative;
	margin-bottom:10px;
	font-size:15px;
	font-family:'PT Serif',serif;
}

.main-footer .links-widget .list li a{
	position:relative;
	display:block;
	line-height:24px;
	color:#9198a6;
	text-transform:capitalize;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	transition:all 500ms ease;
}

.main-footer .links-widget .list li a:hover{
	color:#fc2c62;
}

.main-footer .footer-bottom{
	position:relative;
	font-size:14px;
	line-height:24px;
	text-align:center;
}

.main-footer .footer-bottom .bottom-title{
	position:relative;
	font-size:42px;
	line-height:1.4em;
	font-weight:800;
	margin-bottom:20px;
}

.main-footer .footer-bottom .bottom-box{
	position:relative;
	padding:40px 0px;
	border-top:1px solid rgba(255,255,255,0.10);
}

.main-footer .footer-bottom .social-links{
	margin-bottom:25px;
}

.main-footer .footer-bottom .social-links li{
	position:relative;
	display:inline-block;
	margin:0px 7px;
}

.main-footer .footer-bottom .social-links li a{
	display:block;
	width:40px;
	height:40px;
	line-height:38px;
	color:#ffffff;
	font-size:14px;
	border:1px solid rgba(255,255,255,0.20);
	border-radius:50%;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	transition:all 500ms ease;
}

.main-footer .footer-bottom .social-links li a:hover{
	background:#fc2c62;
	color:#ffffff;
	border-color:#fc2c62;
}

/***

====================================================================
	What We Do
====================================================================

***/

.what-we-do{
	position:relative;
	padding:100px 0px 70px;
}

.service-box{
	position:relative;
	margin-bottom:30px;
}

.service-box .inner-box{
	position:relative;
	display:block;
	padding:30px;
	border:1px solid #e9e9e9;
	border-radius:3px;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	transition:all 500ms ease;
}

.service-box .inner-box:hover{
	background:#fc2c62;
	color:#ffffff;
	border-color:#fc2c62;
}

.service-box .inner-box .overlay-link{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
}

.service-box .inner-box .icon-box{
	position:absolute;
	left:40px;
	top:40px;
	line-height:1.2em;
	font-size:48px;
	color:#fc2c62;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	transition:all 500ms ease;
}

.service-box .inner-box:hover .icon-box{
	color:#ffffff;
}


.service-box .inner-box .title{
	position:relative;
	padding-left:70px;
	margin-bottom:15px;
}

.service-box .inner-box .title > h3,
.service-box .inner-box .title > p{
	font-size:24px;
	font-weight:700;
	color:#292929;
	line-height:1.4em;
	margin-bottom: 0;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	transition:all 500ms ease;
}

.service-box .inner-box:hover h3,
.service-box .inner-box:hover p{
	color:#ffffff;
}

.service-box .inner-box .txt{
	font-style:italic;
	font-weight:300;
	line-height:1.8em;
}

.service-box .inner-box .text-content{
	font-family:'PT Serif',serif;
	line-height:1.8em;
}

/***

====================================================================
	About Us Section
====================================================================

***/

.about-section{
	position:relative;
}

.about-section .image-column,
.about-section .content-column{
	position:relative;
}

.about-section .image-column .image{
	position:relative;
	padding-top:60px;
}

.about-section .image-column img{
	display:block;
	max-width:100%;
}

.about-section .content-column .inner{
	position:relative;
	padding:80px 0px;
}

.about-section .content-column .subtitle{
	position:relative;
	margin-bottom:15px;
}

.about-section .content-column .subtitle a{
	display:inline-block;
	font-size:13px;
	font-weight:500;
	line-height:24px;
	padding:3px 10px;
	color:#b2b2b2;
	background:#f7f7f7;
	margin-right:3px;
	text-transform:uppercase;
	border-radius:3px;
}

.about-section.bg-lightgrey .content-column .subtitle a{
	background:#ffffff;
}

.about-section .content-column h2{
	font-size:40px;
	font-weight:700;
	color:#2a2a2a;
	margin-bottom:15px;
	line-height:1.3em;
}

.about-section .content-column h2 a{
	color:#2a2a2a;
}

.about-section .content-column h3{
	font-size:18px;
	font-weight:400;
	margin-bottom:30px;
	line-height:1.5em;
}

.about-section .content-column .desc{
	position:relative;
	font-family:'PT Serif',serif;
	line-height:1.8em;
	margin-bottom:50px;
}

.about-section .content-column .info{
	position:relative;
	float:left;
	padding-left:100px;
	padding-top:10px;
	min-height:70px;
	margin-bottom:50px;
}

.about-section .content-column .info .image-thumb{
	position:absolute;
	left:0px;
	top:0px;
	width:75px;
}

.about-section .content-column .info .title{
	font-size:18px;
	color:#323131;
	font-weight:700;
}

.about-section .content-column .info .designation{
	font-family:'PT Serif',serif;
	font-style:italic;
	color:#a1a1a1;
}

.about-section .content-column .signature{
	position:relative;
	float:right;
	margin-bottom:50px;
}

.about-section .content-column .theme-btn{
	border-color:#fc2c62;
	padding:10px 30px;
}

/***

====================================================================
	Testimonials Two
====================================================================

***/

.testimonial-style-two{
	position:relative;
	padding:100px 0px 120px;
	background:#fc2c62 url(../images/background/map-pattern.png) center center no-repeat;
}


.testimonial-style-two .sec-title{
	margin-bottom:60px;
	text-align:center;
}

.testimonial-style-two .sec-title h2{
	color:#ffffff;
}

.testimonial-style-two .slide-item{
	position:relative;
}

.testimonial-style-two .slide-item .inner{
	position:relative;
	display:block;
	background:#ffffff;
	border-radius:5px;
	padding:40px 40px 30px;
}

.testimonial-style-two .slide-item .content{
	position:relative;
}

.testimonial-style-two .slide-item .content .text{
	color:#000000;
	font-size:16px;
	font-family:'PT Serif',serif;
	margin-bottom:15px;
}

.testimonial-style-two .slide-item .content .date{
	position:relative;
	font-size:15px;
	font-weight:400;
}

.testimonial-style-two .slide-item .info{
	position:relative;
	padding-left:90px;
	padding-top:12px;
	min-height:70px;
	margin-bottom:25px;
}

.testimonial-style-two .slide-item .info .author-thumb{
	position:absolute;
	left:0px;
	top:0px;
	width:70px;
	height:70px;
}

.testimonial-style-two .slide-item .info h4{
	font-size:18px;
	font-weight:600;
	color:#2b2b2b;
	line-height:1.2em;
}

.testimonial-style-two .slide-item .info .location{
	font-size:14px;
	font-weight:400;
	color:#7d7d7d;
}

.testimonial-style-two .owl-dots{
	display:none;
}

.testimonial-style-two .owl-nav{
	padding:0px;
	margin:0px;
	display:block;
}

.testimonial-style-two .owl-nav .owl-prev,
.testimonial-style-two .owl-nav .owl-next{
	position:absolute;
	display:block;
	top:50%;
	margin-top:-15px;
	width:48px;
	height:48px;
	background:none;
	color:#ffffff;
	font-size:24px;
	line-height:44px;
	border:2px solid #ffffff;
	text-align:center;
	border-radius:50%;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	transition:all 500ms ease;
}

.testimonial-style-two .owl-nav .owl-prev{
	left:-100px;
}

.testimonial-style-two .owl-nav .owl-next{
	right:-100px;
	padding-left:2px;
}

.testimonial-style-two .owl-nav .owl-prev:hover,
.testimonial-style-two .owl-nav .owl-next:hover{
	color:#292929;
	background:#ffffff;
}


/***

====================================================================
	Page Title Style
====================================================================

 ***/

.page-title{
	position:relative;
	padding:220px 0px 130px;
	text-align:center;
	background-size:cover;
	background-repeat:no-repeat;
	background-position:center center;
}

.page-title:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	background-color:rgba(0,0,0,0.30);
}

.page-title .inner-box{
	position:relative;
}

.page-title h1{
	font-size:54px;
	margin-bottom:10px;
	font-weight:700;
	color:#ffffff;
	letter-spacing:0px;
	text-transform:uppercase;
}

.page-title .bread-crumb{
	position:relative;
	color:#ffffff;
}

.page-title .bread-crumb li{
	position:relative;
	color:#fc2c62;
	margin-right:10px;
	display:inline-block;
	text-transform:uppercase;
}

.page-title .bread-crumb li:last-child{
	margin:0px;
}

.page-title .bread-crumb li:before{
	position:absolute;
	content:'/';
	right:0px;
	top:0px;
	color:#ffffff;
	font-size:16px;
}

.page-title .bread-crumb li:last-child:before{
	display:none;
}

.page-title .bread-crumb li a{
	position:relative;
	font-size:16px;
	color:#ffffff;
	margin:0px 5px;
	padding-right:10px;
	transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
}

.page-title .bread-crumb li a:hover,
.page-title .bread-crumb li a.current{
	position:relative;
	color:#fc2c62;
}

/***

====================================================================
	Team Section
====================================================================

***/

.team-section{
	position:relative;
	padding:100px 0px 50px;
}

.team-member{
	position:relative;
	margin-bottom:50px;
}

.team-member .inner-box{
	position:relative;
	display:block;
	text-align:center;
}

.team-member .inner-box .image{
	position:relative;
	display:block;
	border-radius:5px;
	overflow:hidden;
}

.team-member .inner-box .image img{
	display:block;
	width:100%;
}

.team-member .lower-content{
	position:relative;
	padding:25px 15px 0px;
}

.team-member h3{
	font-size:19px;
	color:#222328;
	font-weight:700;
	text-transform:capitalize;
	line-height:1.6em;
}

.team-member h3 a{
	color:#222328;
}

.team-member .designation{
	position:relative;
	color:#fc2c62;
	font-style:italic;
	text-transform:capitalize;
	font-family:'PT Serif',serif;
	margin-bottom:10px;
}

.team-member .social-links li{
	position:relative;
	display:inline-block;
	margin:0px 2px;
}

.team-member .social-links li a{
	position:relative;
	display:block;
	width:36px;
	height:36px;
	border:1px solid #e9e9e9;
	color:#cccccc;
	font-size:14px;
	line-height:34px;
	text-align:center;
	border-radius:50%;
	transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
}

.team-member .social-links li a:hover{
	background:#fc2c62;
	color:#ffffff;
	border-color:#fc2c62;
}


/***

====================================================================
	Gallery Section
====================================================================

***/

.gallery-section{
	position:relative;
	padding:100px 0px 70px;
}

.gallery-section.fullwidth-gallery{
	padding-bottom:0px;
}

.gallery-section .outer-box{
	position:relative;
}

.gallery-section .filters{
	position:relative;
	text-align:center;
	margin-bottom:40px;
}

.gallery-section .filters li{
	position:relative;
	display:inline-block;
	padding:0px 0px;
	line-height:24px;
	color:#9f9f9f;
	cursor:pointer;
	text-transform:uppercase;
	border-radius:3px;
	font-size:13px;
	font-weight:600;
	margin:0px 15px 10px;
	transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
}

.gallery-section .filters li:after{
	content:'/';
	position:absolute;
	right:-20px;
	top:0px;
	line-height:24px;
	font-size:14px;
	color:#9f9f9f;
}

.gallery-section .filters li:last-child:after{
	display:none;
}

.gallery-section .filters li:hover,
.gallery-section .filters li.active{
	color:#25292f;
}

.default-portfolio-item{
	position:relative;
	margin-bottom:30px;
}

.default-portfolio-item.mix{
	display:none;
}

.fullwidth-gallery .default-portfolio-item{
	padding:0px !important;
	margin:0px !important;
}

.default-portfolio-item .inner-box{
	position:relative;
	width:100%;
	border-radius:3px;
	overflow:hidden;
}

.gallery-section.fullwidth-gallery .default-portfolio-item .inner-box{
	border-radius:0px;
}

.default-portfolio-item .image-box{
	position:relative;
	display:block;
	border-radius:3px;
}

.default-portfolio-item .image-box img{
	position:relative;
	display:block;
	border-radius:3px;
	width:100%;
}

.default-portfolio-item .overlay-box{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	padding:0px;
	color:#ffffff;
	opacity:0;
	text-align:center;
	transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transform:scaleX(0);
	-ms-transform:scaleX(0);
	-o-transform:scaleX(0);
	-moz-transform:scaleX(0);
	transform:scaleX(0);
}

.style-two .default-portfolio-item .overlay-box{
	border:none;
}

.default-portfolio-item .inner-box:hover .overlay-box{
	top:0px;
	opacity:1;
	-webkit-transform:scaleX(1);
	-ms-transform:scaleX(1);
	-o-transform:scaleX(1);
	-moz-transform:scaleX(1);
	transform:scaleX(1);
}

.default-portfolio-item .overlay-inner{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:table;
	vertical-align:middle;
	padding:10px 20px;
	background:rgba(252,44,98,0.80);
}

.default-portfolio-item .overlay-inner .content{
	position:relative;
	display:table-cell;
	vertical-align:middle;
}

.default-portfolio-item .overlay-inner .option-btn{
	position:relative;
	display:inline-block;
	line-height:44px;
	color:#222222;
	font-size:15px;
	width:44px;
	height:44px;
	margin:0px 3px;
	background:#ffffff;
	border-radius:50%;
	transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
}

.default-portfolio-item .overlay-inner .option-btn:hover{
	background:#242424;
	color:#ffffff;
}

.fancybox-next span,
.fancybox-prev span{
	background-image:none !important;
	width:44px !important;
	height:44px !important;
	line-height:44px !important;
	text-align:center;
}

.fancybox-next span:before,
.fancybox-prev span:before{
	content:'';
	position:absolute;
	font-family: 'FontAwesome';
	left:0px;
	top:0px;
	font-size:12px;
	width:44px !important;
	height:44px !important;
	line-height:44px !important;
	background-color:rgba(28,28,28,0.40) !important;
	color:#ffffff;
	visibility:visible;
	transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
}

.fancybox-next span:before{
	content:'\f178';
}

.fancybox-prev span:before{
	content:'\f177';
}

.fancybox-next:hover span:before,
.fancybox-prev:hover span:before{
	background-color:#ffffff !important;
	color:#000000;
}

.fancybox-type-image .fancybox-close{
	right:0px;
	top:0px;
	width:45px;
	height:45px;
	background:url(../images/icons/icon-cross.png) center center no-repeat;
	background-color:rgba(17,17,17,0.50) !important;
}

.fancybox-type-image .fancybox-close:hover{
	background-color:#000000 !important;
}

.fancybox-type-image .fancybox-skin{
	padding:0px !important;
}


/***

====================================================================
	Styled Pagination
====================================================================

***/

.styled-pagination{
	position:relative;
	padding-top:30px;
	padding-bottom:20px;
}

.events .styled-pagination{
	padding-bottom:0px;
}

.styled-pagination ul{
	position:relative;
	display:inline-block;
}

.styled-pagination li{
	position:relative;
	display:block;
	float:left;
	margin:0px 10px 10px 0px;
}

.styled-pagination li a{
	position:relative;
	display:inline-block;
	line-height:20px;
	padding:8px 5px;
	height:40px;
	font-size:15px;
	text-transform:capitalize;
	min-width:40px;
	border:2px solid #e0e0e0;
	background:none;
	color:#cdcdcd;
	font-weight:500;
	text-align:center;
	border-radius:20px;
	transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
}

.styled-pagination li a.next,
.styled-pagination li a.prev{
	background:#242424;
	color:#ffffff;
	padding:9px 20px;
	border-radius:3px;
	border:2px solid #242424;
}

.styled-pagination li a:hover,
.styled-pagination li a.active{
	color:#fc2c62;
	border-color:#fc2c62;
	background:none;
}

/***

====================================================================
	Single Cause Section
====================================================================

***/

.single-cause-section{
	position:relative;
	padding:100px 0px;
}

.cause-details{
	position:relative;
}

.cause-details .upper-content{
	position:relative;
}

.cause-details .image-box{
	position:relative;
	display:block;
	margin-bottom:40px;
}

.cause-details .image-box img{
	position:relative;
	display:block;
	width:100%;
}

.cause-details .upper-box h3{
	font-size:42px;
	color:#363636;
	font-weight:600;
	margin-bottom:20px;
	text-align:center;
	line-height:1.4em;
}

.cause-details .cause-header{
	position:relative;
	border-bottom:1px solid #e9e9e9;
}

.cause-details .donate-box{
	position:relative;
	float:left;
	margin-bottom:25px;
}

.cause-details .donate-box .donate-bar{
	position:relative;
	width:100%;
	height:12px;
	margin-bottom:15px;
}

.cause-details .donate-box .donate-bar .bar-inner{
	position:relative;
	width:100%;
	height:12px;
	background:#f7f7f7;
	border-radius:6px;
}

.cause-details .donate-box .donate-bar .bar-inner .bar{
	position:absolute;
	left:0%;
	top:0px;
	height:12px;
	width:0px;
	background:#fc2c62 url(../images/icons/progress-bar-fill.jpg) left center repeat-x;
	border-radius:6px;
	-webkit-transition:all 2000ms ease;
	-ms-transition:all 2000ms ease;
	-o-transition:all 2000ms ease;
	-moz-transition:all 2000ms ease;
	transition:all 2000ms ease;
}

.cause-details .donate-box .donate-info{
	position:relative;
	color:#c9c9c9;
	line-height:24px;
	font-size:14px;
}

.cause-details .donate-box .donate-info strong{
	text-transform:uppercase;
	font-weight:700;
	font-size:13px;
	color:#363636;
}

.cause-details .donate-box .donate-info .goal{
	color:#ff4d49;
}

.cause-details .cause-header .donate-link{
	position:relative;
	float:right;
	padding:0px 15px;
	margin-bottom:35px;
}

.cause-details .cause-header .donate-link a{
	color:#242424;
	padding:8px 25px;
	border-radius:24px;
}

.cause-details .cause-header .donate-link a:hover{
	color:#ffffff;
}

.cause-details .content{
	position:relative;
	padding:30px 0px;
	border-bottom:1px solid #e9e9e9;
}

.cause-details .content .bold-text{
	font-size:18px;
	color:#363636;
	font-family:'PT Serif',serif;
	margin-bottom:25px;
}

.cause-details .content p{
	margin-bottom:20px;
}

.list-style-one{
	font-size:18px;
	font-family:'PT Serif',serif;
	margin-bottom:20px;
	color:#363636;
}

.list-style-one li{
	position:relative;
	padding-left:30px;
	line-height:30px;
	margin-bottom:12px;
}

.list-style-one li:before{
	content:'\f16d';
	font-size:18px;
	position:absolute;
	left:0px;
	top:0px;
	line-height:30px;
	color:#fc2c62;
	font-family: "Flaticon";
}

.cause-details .content .list-style-one{
	margin-left:20px;
}

.single-cause-section .recent-causes{
	position:relative;
	padding-top:70px;
}

.single-cause-section .recent-causes .title{
	position:relative;
	margin-bottom:40px;
}

.single-cause-section .recent-causes .title h2{
	position:relative;
	font-size:42px;
	color:#242424;
	font-weight:700;
	text-transform:capitalize;
	line-height:1.4em;
}

.single-cause-section .recent-causes-carousel .owl-dots{
	display:none;
}

.single-cause-section .recent-causes-carousel .owl-nav{
	position:absolute;
	right:0px;
	top:-90px;
}

.single-cause-section .recent-causes-carousel .owl-nav .owl-next,
.single-cause-section .recent-causes-carousel .owl-nav .owl-prev{
	position:relative;
	display:inline-block;
	width:40px;
	height:40px;
	line-height:36px;
	border:2px solid #e9e9e9;
	color:#cccccc;
	font-size:24px;
	text-align:center;
	margin-left:10px;
	border-radius:50%;
}

.single-cause-section .recent-causes-carousel .owl-nav .owl-next:hover,
.single-cause-section .recent-causes-carousel .owl-nav .owl-prev:hover{
	color:#fc2c62;
	border-color:#fc2c62;
}

/***

====================================================================
	Donation Section style
====================================================================

***/

.donation-section{
	position:relative;
	padding:120px 0px;
}

.donation-section .upper-content{
	position:relative;
	padding-bottom:40px;
}

.donation-section .upper-content .column{
	margin-bottom:30px;
}

.donation-section .upper-content .image-box{
	padding-right:20px;
}

.donation-section .upper-content .image-box img{
	display:block;
	width:100%;
}

.donation-section .upper-content h2{
	font-size:42px;
	color:#303030;
	font-weight:700;
	line-height:1.2em;
}

.donation-section .upper-content p{
	margin-bottom:20px;
	font-family:'PT Serif',serif;
}

.donation-section .upper-content h3{
	font-size:28px;
	color:#303030;
	font-weight:300;
	line-height:1.5em;
	margin-bottom:25px;
}

.donation-form-outer{
	position:relative;
	padding:50px 40px 60px;
	border:1px solid #e9e9e9;
	border-radius:3px;
}

.donation-form-outer h3{
	font-size:26px;
	font-weight:600;
	color:#25292f;
	margin-bottom:30px;
}

.donation-form-outer hr{
	margin-bottom:40px;
	border-color:#e9e9e9;
}

.donation-form-outer .form-group{
	position:relative;
	margin-bottom:30px;
}

.donation-form-outer input[type="text"],
.donation-form-outer input[type="email"],
.donation-form-outer input[type="tel"],
.donation-form-outer input[type="url"],
.donation-form-outer input[type="password"],
.donation-form-outer select,
.donation-form-outer textarea{
	position:relative;
	display:block;
	width:100%;
	line-height:34px;
	padding:8px 20px;
	font-size:14px;
	background:#ffffff;
	border:1px solid #e9e9e9;
	height:52px;
	border-radius:3px;
}

.donation-form-outer input.other-amount{
	height:62px;
	padding:15px 20px;
	background:#f9f9f9;
	line-height:30px;
	top:5px;
}

.donation-form-outer select{
	cursor:pointer;
}

.donation-form-outer .field-label{
	position:relative;
	display:block;
	font-weight:500;
	font-size:14px;
	color:#252525;
	line-height:28px;
	margin-bottom:10px;
}

.donation-form-outer .field-label .required{
	color:#ff0000;
	font-size:14px;
}

.donation-form-outer .radio-select{
	position:relative;
	float:left;
	margin-right:25px;
	margin-bottom:10px;
	line-height:80px;
}

.donation-form-outer .radio-select label{
	position:relative;
	display:block;
	width:80px;
	height:80px;
	text-align:center;
	color:#252525;
	font-size:20px;
	font-weight:600;
	text-transform:uppercase;
	background:#f9f9f9;
	border-radius:50%;
	-ms-border-radius:50%;
	cursor:pointer;
}

.donation-form-outer .radio-select input[type="radio"]{
	display:none;
}

.donation-form-outer .radio-select input[type="radio"]:checked+label{
	color:#ffffff;
	background:#fc2c62;
}

.donation-form-outer button{
	padding:9px 30px;
}


.form-group .ui-selectmenu-button.ui-button{
	width:100%;
	background:#ffffff;
	font-size:14px;
	font-style:normal;
	height:52px;
	padding:10px 20px;
	border:1px solid #e3e3e3;
	line-height:30px;
	color:#333333;
	border-radius:0px;
}

.form-group .ui-button .ui-icon{
	background:none;
	position:relative;
	top:3px;
	text-indent:0px;
	color:#333333;
}

.form-group .ui-button .ui-icon:before{
	font-family: 'FontAwesome';
	content: "\f107";
	position:absolute;
	right:0px;
	top:4px !important;
	top:13px;
	width:10px;
	height:20px;
	display:block;
	color:#333333;
	line-height:20px;
	font-size:16px;
	font-weight:normal;
	text-align:center;
	z-index:5;
}

.ui-widget.ui-widget-content{
	border:1px solid #e0e0e0;
	font-family:'Montserrat',sans-serif;
}

.ui-menu .ui-menu-item{
	font-size:14px;
	border-bottom:1px solid #e0e0e0;
}

.ui-menu .ui-menu-item:last-child{
	border:none;
}

.ui-menu .ui-menu-item-wrapper{
	position:relative;
	display:block;
	padding:8px 20px ;
	font-size:14px;
	line-height:24px;
}

/***

====================================================================
	Contact Section
====================================================================

***/

.contact-section{
	position:relative;
	padding:100px 0px 70px;
}

.contact-section .upper-title{
	position:relative;
	margin-bottom:35px;
}

.contact-section h2{
	font-size:36px;
	font-weight:700;
	color:#222222;
	text-transform:capitalize;
	margin-bottom:7px;
}

.contact-section .text{
	position:relative;
	line-height:1.8em;
}

.contact-section .contact-column{
	margin-bottom:30px;
}

.contact-us-form{
	position:relative;
}

.contact-us-form .row{
	margin:0px -12px;
}

.contact-us-form .form-group{
	position:relative;
	margin-bottom:24px;
	padding:0px 12px;
	font-size:15px;
}

.contact-us-form input[type="text"],
.contact-us-form input[type="email"],
.contact-us-form input[type="password"],
.contact-us-form input[type="tel"],
.contact-us-form input[type="number"],
.contact-us-form input[type="url"],
.contact-us-form select,
.contact-us-form textarea{
	position:relative;
	display:block;
	width:100%;
	background:#eff0f4;
	line-height:26px;
	padding:10px 20px;
	height:48px;
	font-size:15px;
	border:1px solid #e9e9e9;
	border-radius:3px;
	transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
}

.contact-us-form select{
	-webkit-appearance:none;
	-ms-appearance:none;
	-moz-appearance:none;
	-o-appearance:none;
	background:#ffffff url(../images/icons/icon-select.png) right center no-repeat;
	cursor:pointer;
}

.contact-us-form select option{
	text-indent:20px;
}

.contact-us-form textarea{
	resize:none;
	height:200px;
}

.contact-us-form input:focus,
.contact-us-form select:focus,
.contact-us-form textarea:focus{
	border-color:#fc2c62;
	background:none;
}

.contact-us-form input.error,
.contact-us-form select.error,
.contact-us-form textarea.error{
	border-color:#ff0000;
	background:none;
}

.contact-us-form label.error{
	display:block;
	font-weight:normal;
	font-size:13px;
	text-transform:capitalize;
	line-height:24px;
	color:#ff0000;
	padding-top:10px;
}

.contact-us-form .theme-btn{
	padding-left:40px;
	padding-right:40px;
}

.contact-section .contact-column .inner{
	padding-left:40px;
}

.contact-section .contact-info{
	position:relative;
	display:block;
}

.contact-section .contact-info li{
	position:relative;
	padding-left:90px;
	display:block;
	line-height:24px;
	color:#767676;
	font-family:'PT Serif',serif;
	margin-bottom:30px;
	min-height:60px;
}

.contact-section .contact-info li:last-child{
	margin-bottom:0px;
}

.contact-section .contact-info li h4{
	font-size:14px;
	font-weight:600;
	color:#242424;
	text-transform:uppercase;
	margin-bottom:5px;
}

.contact-section .contact-info li .icon{
	position:absolute;
	left:0px;
	top:-3px;
	width:60px;
	line-height:56px;
	text-align:center;
	font-size:24px;
	color:#fc2c62;
	border:2px solid #e9e9e9;
	border-radius:50%;
}

/***

====================================================================
	Map Section Style
====================================================================

***/

.map-section{
	position:relative;
}

.map-section .map-container{
	position:relative;
	z-index:10;
}

.map-data{
	text-align:center;
	font-size:14px;
	font-weight:400;
	line-height:1.8em;
}

.map-canvas{
	position:relative;
	height:500px;
}

.map-data h6{
	font-size:18px;
	font-weight:500;
	text-align:center;
	margin-bottom:5px;
	color:#121212;
}


/***

====================================================================
	Sidebar Page
====================================================================

***/

.sidebar-page-container{
	position:relative;
	padding:100px 0px 70px;
}

.sidebar-page-container .sidebar-side,
.sidebar-page-container .content-side{
	margin-bottom:30px !important;
}

.sidebar{
	position:relative;
}

.blog-sidebar{
	padding-left:15px;
}

.sidebar .sidebar-widget{
	position:relative;
	margin-bottom:50px;
}

.sidebar .sidebar-widget .sidebar-title{
	position:relative;
	margin-bottom:30px;
	padding-bottom:20px;
	border-bottom:1px solid #e9e9e9;
}

.sidebar .sidebar-widget .sidebar-title h3{
	position:relative;
	font-size:28px;
	padding-right:16px;
	line-height:1.4em;
	text-transform:capitalize;
	font-weight:700;
	color:#25292f;
}

.sidebar .search-box .form-group{
	position:relative;
	margin:0px;
}

.sidebar .search-box .form-group input[type="text"],
.sidebar .search-box .form-group input[type="search"]{
	position:relative;
	line-height:24px;
	padding:15px 60px 15px 30px;
	border:1px solid #e9e9e9;
	background:#ffffff;
	color:#222222;
	display:block;
	font-size:15px;
	width:100%;
	height:60px;
	border-radius:30px;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.sidebar .search-box .form-group input:focus{
	border-color:#fc2c62;
}

.sidebar .search-box .form-group button{
	position:absolute;
	right:0px;
	top:0px;
	height:60px;
	width:70px;
	line-height:60px;
	text-align:center;
	display:block;
	font-size:14px;
	color:#ffffff;
	background:#fc2c62;
	border-radius:0px 30px 30px 0px;
	font-weight:normal;
}

.sidebar .search-box .form-group input:focus + button,
.sidebar .search-box .form-group button:hover{
	background:#fc2c62;
}

.sidebar .popular-posts .post{
	position:relative;
	font-size:14px;
	color:#777777;
	padding:0px 0px;
	padding-left:130px;
	min-height:90px;
	margin-bottom:30px;
}

.sidebar .popular-posts .post:last-child{
	margin-bottom:0px;
}

.sidebar .popular-posts .post .post-thumb{
	 position:absolute;
	 left:0px;
	 top:0px;
	 width:100px;
	border-radius:3px;
}

.sidebar .popular-posts .post .post-thumb img{
	display:block;
	width:100%;
	border-radius:3px;
}

.sidebar .popular-posts .post h4{
	font-size:18px;
	margin:0px 0px 10px;
	font-weight:500;
	color:#25292f;
}

.sidebar .popular-posts .post h4 a{
	color:#25292f;
}

.sidebar .popular-posts .post a,
.sidebar .popular-posts .post a:hover{
	color:#fc2c62;
}

.sidebar .popular-posts .post-meta ul li{
	position:relative;
	float:left;
	margin-right:25px;
	line-height:24px;
	color:#9f9f9f;
}

.sidebar .popular-posts .post-meta ul li .icon{
	padding-right:5px;
}

.sidebar .popular-posts .post-meta ul li a{
	position:relative;
	line-height:24px;
	color:#9f9f9f;
}

.sidebar .popular-posts .post-meta ul li a:hover,
.sidebar .popular-posts .post-meta .share-btn a:hover{
	color:#fc2c62;
}

.sidebar .popular-tags a{
	position:relative;
	display:inline-block;
	line-height:24px;
	padding:7px 20px;
	margin:0px 7px 10px 0px;
	color:#777777;
	background:#eff4f4;
	text-align:center;
	font-weight:500;
	text-transform:uppercase;
	font-size:14px;
	border-radius:18px;
	transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
}

.sidebar .popular-tags a:hover{
	background-color:#fc2c62;
	border-color:#fc2c62;
	color:#ffffff;
}

.sidebar .list{
	position:relative;
	font-family:'PT Serif',serif;
}

.sidebar .list li{
	position:relative;
	line-height:24px;
	margin-bottom:15px;
}

.sidebar .list li:last-child{
	margin:0px;
}

.sidebar .list li a{
	position:relative;
	display:block;
	color:#767676;
	font-size:16px;
	font-weight:400;
	line-height:24px;
}

.sidebar .list li a:hover{
	color:#fc2c62;
}

.sidebar .archives-list{
	position:relative;
}

.sidebar .archives-list li{
	position:relative;
	line-height:24px;
	padding:10px 0px;
	border-bottom:1px dashed #d0d0d0;
}

.sidebar .archives-list li:last-child{
	border-bottom:none;
	padding-bottom:0px;
}

.sidebar .archives-list a{
	position:relative;
	display:block;
	color:#666666;
	font-size:14px;
	font-weight:400;
	line-height:24px;
}

.sidebar .archives-list a:hover{
	color:#fc2c62;
}

.sidebar .text-widget{
	position:relative;
}

.sidebar .text-widget .text{
	font-size:14px;
	color:#666666;
}

.sidebar .latest-tweets{
	position:relative;
	color:#999999;
}

.sidebar .latest-tweets .owl-dots{
	display:none !important;
}

.sidebar .latest-tweets .owl-nav{
	position:absolute;
	right:0px;
	bottom:-50px;
}

.sidebar .latest-tweets .widget-bottom{
	padding:10px 0px;
	border-top:1px solid #e0e0e0;
}

.sidebar .latest-tweets .widget-bottom a{
	color:#707070;
}

.sidebar .latest-tweets .time{
	color:#555555;
	margin-bottom:5px;
}

.sidebar .latest-tweets .owl-prev{
	width:20px;
	height:20px;
	padding:0px !important;
	background:none !important;
	line-height:20px !important;
	color:#707070 !important;
	text-align:center;
	margin:0px 0px 0px 0px !important;
}

.sidebar .latest-tweets .owl-next{
	width:20px;
	height:20px;
	padding:0px !important;
	background:none !important;
	line-height:20px !important;
	color:#707070 !important;
	text-align:center;
	margin:0px 0px 0px 0px !important;
}

.sidebar .latest-tweets .owl-prev .fa:before,
.sidebar .latest-tweets .owl-next .fa:before{
	line-height:24px;
	font-size:24px;
}

.sidebar .latest-tweets .owl-prev:hover,
.sidebar .latest-tweets .owl-next:hover{
	color:#cccccc !important;
}

.sidebar .latest-tweets a{
	color:#101010;
}

/* Tabbed Links*/
.sidebar .tabbed-links{
	position:relative;
}

.sidebar .tabbed-nav{
	position:relative;
	font-family: 'Merriweather Sans', sans-serif;
	border:1px solid #e0e0e0;
	border-radius:5px;
}

.sidebar .tabbed-nav li{
	position:relative;
	border-bottom:1px solid #f0f0f0;
}

.sidebar .tabbed-nav li:last-child{
	border-bottom:none;
}

.sidebar .tabbed-nav li a{
	position:relative;
	display:block;
	color:#1c1c1c;
	text-transform:uppercase;
	font-size:14px;
	font-weight:700;
	line-height:24px;
	padding:13px 25px;
	transition:all 300ms ease-in;
	-webkit-transition:all 300ms ease-in;
	-ms-transition:all 300ms ease-in;
	-o-transition:all 300ms ease-in;
	-moz-transition:all 300ms ease-in;
}

.sidebar .tabbed-nav li:hover a,
.sidebar .tabbed-nav li.current a{
	color:#fc2c62;
}

.sidebar .gallery-widget .images-outer{
	position:relative;
	margin:0px -4px;
}

.sidebar .gallery-widget .image-box{
	position:relative;
	float:left;
	width:33.333%;
	padding:0px 4px;
	margin-bottom:8px;
}

.sidebar .gallery-widget .image-box img{
	position:relative;
	display:block;
	width:100%;
	border-radius:3px;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.sidebar .gallery-widget .image-box img:hover{
	opacity:0.70;
}


.sidebar .files li{
	position:relative;
	margin-bottom:10px;
}

.sidebar .files li:last-child{
	margin-bottom:0;
}

.sidebar .files li a{
	position:relative;
	display:block;
	color:#ffffff;
	text-transform:uppercase;
	background:#fc2c62;
	font-size:13px;
	font-weight:400;
	line-height:24px;
	padding:13px 20px;
	transition:all 300ms ease-in;
	-webkit-transition:all 300ms ease-in;
	-ms-transition:all 300ms ease-in;
	-o-transition:all 300ms ease-in;
	-moz-transition:all 300ms ease-in;
}

.sidebar .files li a .fa{
	position:relative;
	top:-2px;
	line-height:24px;
	padding-right:5px;
}

.sidebar .files li a:after {
    content: "\f019";
    font-family: "FontAwesome";
    font-size: 13px;
    right: 10px;
	top:50%;
	margin-top:-15px;
	width:30px;
    line-height: 30px;
	color:#ffffff;
	text-align:center;
    position: absolute;
	background:rgba(255,255,255,0.20);
}

.sidebar .files li:hover a{
	background:#1c1c1c !important;
}

.sidebar .files li:hover a .fa{
	color:#fc2c62;
}


/* Contact Widget*/

.sidebar .contact-widget .widget-content{
	position:relative;
	padding:60px 32px;
	background:#222222;
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center top;
}

.sidebar .contact-widget .widget-content:before{
	content:'';
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	background:rgba(0,0,0,0.90);
}

.sidebar .contact-widget li{
	position:relative;
	padding-left:35px;
	margin-bottom:25px;
	line-height:22px;
	color:#e8e8e8;
	font-size:14px;
}

.sidebar .contact-widget li:last-child{
	margin-bottom:0px;
}

.sidebar .contact-widget li .icon-box{
	position:absolute;
	left:0px;
	top:0px;
	width:20px;
	text-align:center;
	line-height:24px;
	font-size:14px;
	color:#fc2c62;
}

.sidebar .contact-widget li h4{
	font-size:13px;
	font-weight:700;
	color:#ffffff;
	text-transform:uppercase;
	margin-bottom:5px;
}

/* Advise Widget*/

.sidebar .advise-widget .widget-content{
	position:relative;
	padding:85px 30px;
	background:#222222;
	text-align:center;
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center top;
}

.sidebar .advise-widget .widget-content:before{
	content:'';
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	background:rgba(0,0,0,0.80);
}

.sidebar .advise-widget .logo{
	position:relative;
	margin-bottom:20px;
}

.sidebar .advise-widget h2{
	position:relative;
	font-size:22px;
	font-weight:700;
	line-height:30px;
	text-transform:uppercase;
	color:#ffffff;
}

.sidebar .advise-widget h2 span{
	font-family: 'Hind Guntur', sans-serif;
	font-size:24px;
}

.sidebar .advise-widget h4{
	position:relative;
	font-size:16px;
	font-weight:700;
	text-transform:uppercase;
	color:#ffffff;
	margin-bottom:20px;
}

.sidebar .contact-widget li{
	position:relative;
	padding-left:35px;
	margin-bottom:25px;
	line-height:22px;
	color:#e8e8e8;
	font-size:14px;
}

.sidebar .contact-widget li:last-child{
	margin-bottom:0px;
}

.sidebar .contact-widget li .icon-box{
	position:absolute;
	left:0px;
	top:0px;
	width:20px;
	text-align:center;
	line-height:24px;
	font-size:14px;
	color:#fc2c62;
}

.sidebar .contact-widget li h4{
	font-size:13px;
	font-weight:700;
	color:#ffffff;
	text-transform:uppercase;
	margin-bottom:5px;
}

.rangeslider-widget .outer-box{
	position:relative;
	padding:10px 0px 0px;
}

.rangeslider-widget .form-group{
	position:relative;
	color:#bebebe;
	font-weight:500;
	font-size:14px;
	margin:30px 0px 0px;
}

.rangeslider-widget .val-box{
	position:relative;
	display:inline-block;
	margin:7px 5px 0px 0px;
	line-height:24px;
	font-size:14px;
	padding:5px 0px;
	width:50px;
	text-align:center;
	color:#bebebe;
	font-weight:700;
}

.rangeslider-widget .noUi-connect{
	background:#fc2c62 !important;
	box-shadow:none;
}

.rangeslider-widget .noUi-background{
	background:#ededed !important;
}

.rangeslider-widget .noUi-handle{
	top:-7px !important;
	background-color:transparent !important;
	box-shadow:none;
	border:2px solid #fc2c62 !important;
	cursor:pointer;
	width:18px;
	height:18px;
	background:#f8f8f8 !important;
	margin-left:14px;
	border-radius:50%;
}

.rangeslider-widget .noUi-horizontal{
	height:7px !important;
	box-shadow:none !important;
	background:#fc2c62;
}

.rangeslider-widget .btn-style-two{
	padding-left:30px;
	font-size:12px;
	color:#222222;
	padding-right:30px;
}

.sidebar .related-posts .post{
	position:relative;
	font-size:14px;
	color:#cccccc;
	padding:0px 0px;
	padding-left:100px;
	min-height:80px;
	margin-bottom:25px;
}

.sidebar .related-posts .post:last-child{
	margin-bottom:0px;
}

.sidebar .related-posts .post .post-thumb{
	 position:absolute;
	 left:0px;
	 top:0px;
	 width:80px;
}

.sidebar .related-posts .post .post-thumb img{
	display:block;
	width:100%;
}

.sidebar .related-posts .post h4{
	font-size:14px;
	margin:0px;
	font-weight:700;
	color:#222222;
	text-transform:uppercase;
	margin-bottom:3px;
}

.sidebar .related-posts .post h4 a{
	color:#222222;
}

.sidebar .related-posts .post a,
.sidebar .related-posts .post a:hover{
	color:#fc2c62;
}

.sidebar .related-posts .post .price{
	font-size:18px;
	letter-spacing:1px;
	font-weight:700;
	color:#fc2c62;
}

.sidebar .related-posts .post .rating{
	margin-bottom:3px;
}

.sidebar .related-posts .post .rating .fa{
	position:relative;
	display:inline-block;
	margin-right:3px;
	font-size:12px;
	line-height:26px;
	color:#fc2c62;
}

.sidebar .related-posts .post .rating .fa-star-o{
	color:#999999;
}

/***

====================================================================
	Comments Area
====================================================================

 ***/

.sidebar-page-container .comments-area{
	position:relative;
	margin-bottom:50px;
}

.sidebar-page-container .group-title{
	position:relative;
	margin-bottom:30px;
}

.sidebar-page-container .group-title h2{
	font-size:28px;
	text-transform:capitalize;
	color:#222222;
	font-weight:700;
}

.comments-area .comment-box{
	position:relative;
	padding:30px 40px 10px;
	border:1px solid #e9e9e9;
}

.comments-area .comment{
	position:relative;
	margin-bottom:30px;
}

.comments-area .comment .comment-content{
	position:relative;
	padding:0px;
	border-bottom:1px solid #e0e0e0;
	padding-bottom:25px;
}

.comments-area .comment:last-child .comment-content{
	border:0px;
	padding-bottom:0px;
	margin-bottom:0px;
}

.comments-area .reply-comment{
	margin-left:110px;
}

.comments-area .comment .comment-inner{
	position:relative;
	padding-left:90px;
	padding-top:5px;
	margin-bottom:30px;
}

.comments-area .comment:last-child .comment-inner .comment-content{
	padding-bottom:0px;
	border:none;
}

.comments-area .comment .author-thumb{
	position:absolute;
	left:0px;
	top:0px;
	width:64px;
	border-radius:50%;
}

.comments-area .comment .author-thumb img{
	width:100%;
	display:block;
	border-radius:50%;
}

.comments-area .comment .comment-header{
	position:relative;
    top: -5px;
	color:#222222;
	font-size:14px;
	font-weight:400;
	margin-bottom:7px;
}

.comments-area .comment .comment-header h4{
	position:relative;
	color:#313232;
	font-size:16px;
	font-weight:700;
	line-height:24px;
}

.comments-area .comment .comment-header .comment-info{
	font-size:14px;
	color:#c4c4c4;
}

.comments-area .comment .text{
	line-height:1.8em;
	font-size:16px;
	color:#777777;
	font-family:'PT Serif',serif;
}

.comments-area .comment .reply-btn{
	position:absolute;
	right:0px;
	top:0px;
	padding:5px 20px;
	line-height:20px;
	background:#eff4f4;
	color:#939393;
	font-size:12px;
	font-weight:500;
    display: inline-block;
	border-radius:15px;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.comments-area .comment .reply-btn:hover{
	color:#ffffff;
	background:#fc2c62;
}

/***

====================================================================
	Author Info
====================================================================

 ***/

.post-author{
	position:relative;
	margin-bottom:50px;
}

.post-author h3{
	color:#333333;
	text-transform:uppercase;
	margin-bottom:20px;
	font-weight:700;
	font-size:16px;
}

.post-author .inner{
	position:relative;
	min-height:80px;
	padding:40px 30px 40px 180px;
	background:#fcfcfc;
	border-radius:3px;
}

.post-author .author-image{
	position:absolute;
	left:30px;
	top:40px;
	width:120px;
	border-radius:50%;
}

.post-author .author-image img{
	display:block;
	width:100%;
	border-radius:50%;
}

.post-author h4{
	font-size:14px;
	line-height:24px;
	color:#222222;
	font-weight:700;
	margin-bottom:10px;
}

.post-author .text{
	line-height:1.7em;
	margin-bottom:10px;
}

.post-author .social-links a{
	position:relative;
	display:inline-block;
	line-height:24px;
	font-size:14px;
	color:#b3b3b3;
	margin-right:10px;
	transition:all 300ms ease;
}

.post-author a:hover{
	color:#fc2c62;
}

/***

====================================================================
	Comment Form
====================================================================

 ***/

.comment-form .form-group{
	position:relative;
	margin-bottom:30px;
}

.comment-form .form-group .field-label{
	position:relative;
	display:block;
	font-size:14px;
	margin-bottom:7px;
	color:#242424;
}

.comment-form .form-group input[type="text"],
.comment-form .form-group input[type="password"],
.comment-form .form-group input[type="tel"],
.comment-form .form-group input[type="email"],
.comment-form .form-group select,
.comment-form .form-group textarea{
	position:relative;
	display:block;
	width:100%;
	line-height:26px;
	padding:12px 20px;
	color:#242424;
	border:1px solid #e9e9e9;
	height:52px;
	font-size:14px;
	background:#ffffff;
	border-radius:3px;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.comment-form .form-group input[type="text"]:focus,
.comment-form .form-group input[type="password"]:focus,
.comment-form .form-group input[type="tel"]:focus,
.comment-form .form-group input[type="email"]:focus,
.comment-form .form-group select:focus,
.comment-form .form-group textarea:focus{
	border-color:#fc2c62;
}

.comment-form .form-group textarea{
	resize:none;
	height:220px;
}

.comment-form button{
	position:relative;
}

/***

====================================================================
	Events
====================================================================

 ***/

 /* Default Event Post*/

.default-event-post{
	position:relative;
	margin-bottom:40px;
}

.default-event-post .image-box{
	position:relative;
	margin-bottom:30px;
}

.default-event-post .image-box img{
	display:block;
	width:100%;
}

.default-event-post .image-box .overlay-box{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	color:#ffffff;
	text-align:center;
	background:rgba(0,0,0,0.80);
	opacity:0;
	visibility:hidden;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	transition:all 500ms ease;
}

.default-event-post:hover .image-box .overlay-box{
	opacity:1;
	visibility:visible;
}

.default-event-post .image-box .overlay-box .time-counter{
	position:absolute;
	left:0px;
	bottom:30px;
	width:100%;
	padding:0px 15px;
	text-align:center;
}

.default-event-post .inner-box{
	position:relative;
	display:block;
	padding-left:120px;
	min-height:90px;
}

.default-event-post .inner-box .date{
	position:absolute;
	left:0px;
	top:5px;
	font-size:16px;
	width:90px;
	height:90px;
	background:#fc2c62;
	color:#ffffff;
	padding:12px 10px;
	text-align:center;
	line-height:24px;
	border:3px solid rgba(255,255,255,0.50);
	border-radius:50%;
}

.default-event-post .inner-box .date .day{
	display:block;
	font-size:30px;
	line-height:36px;
	font-weight:700;
	font-family:'PT Serif',serif;
}

.default-event-post .inner-box .date .month{
	display:block;
}

.default-event-post .inner-box .post-header{
	position:relative;
	padding-top:5px;
	margin-bottom:10px;
}

.default-event-post .inner-box .post-header h3{
	font-size:22px;
	text-transform:uppercase;
	font-weight:700;
	color:#333333;
	line-height:1.6em;
	margin-bottom:5px;
}

.default-event-post .inner-box .post-header h3 a{
	color:#333333;
}

.default-event-post .inner-box .post-header h3 a:hover{
	color:#fc2c62;
}

.default-event-post .post-meta li{
	position:relative;
	font-size:14px;
	float:left;
	line-height:24px;
	margin-right:30px;
}

.default-event-post .post-meta li:after{
	content:'/';
	position:absolute;
	right:-16px;
	top:0px;
	line-height:24px;
}

.default-event-post .post-meta li:last-child:after{
	display:none;
}

.default-event-post .post-meta li .fa{
	color:#fc2c62;
	padding-right:5px;
}

.default-event-post .post-meta li a{
	color:#666666;
}

.default-event-post .inner-box .text{
	font-family:'PT Serif',serif;
	font-size:16px;
	line-height:1.7em;
}

/***

====================================================================
	Countdown style
====================================================================

***/

.time-counter{
	position:relative;
}

.time-counter .time-countdown{
	position:relative;
}

.style-one .time-countdown .counter-column{
	position:relative;
	display:inline-block;
	margin:0px 20px 10px;
	color:#ffffff;
	font-size:13px;
	line-height:30px;
	text-transform:capitalize;
	text-align:center;
}

.style-one .time-countdown .counter-column .count{
	position:relative;
	display:block;
	font-size:30px;
	line-height:1.4em;
	padding:0px 0px;
	color:#fc2c62;
	font-weight:700;
	letter-spacing:1px;
	font-family:'PT Serif',serif;
}

.time-counter.style-two{
	position:relative;
	margin:0px -20px;
	padding-bottom:30px;
}

.style-two .time-countdown .counter-column{
	position:relative;
	display:block;
	float:left;
	width:25%;
	padding:0px 20px;
	margin-bottom:20px;
	color:#777777;
	font-size:13px;
	line-height:24px;
	text-transform:capitalize;
	text-align:center;
}

.style-two .time-countdown .counter-column .inner{
	position:relative;
	padding:15px 15px 12px;
	border:1px solid #e0e0e0;

}

.style-two .time-countdown .counter-column .count{
	position:relative;
	display:block;
	font-size:30px;
	line-height:1.4em;
	padding:0px 0px;
	color:#fc2c62;
	font-weight:700;
	letter-spacing:1px;
	font-family:'PT Serif',serif;
}

/***

====================================================================
	Event Details
====================================================================

 ***/

.event-details{
	position:relative;
}

.event-details .more-content{
	position:relative;
	padding-top:20px;
	font-size:16px;
	font-family:'PT Serif',serif;
}

.event-details .more-content p{
	line-height:1.7em;
	font-size:16px;
	margin-bottom:20px;
}

.event-details .more-content blockquote{
	position:relative;
	padding:5px 0px;
	border:none;
	font-size:16px;
	font-style:italic;
	line-height:1.8em;
	color:#333333;
	font-weight:normal;
	margin-bottom:20px;
}

.event-details .map-box{
	position:relative;
}