/* GLOBAL */
a {
  color: #1393f7;
}

.nonono {
  display: none;
}

.theme_color {
  color: #1393f7;
}

.btn-style-three:hover,
.btn-style-two:hover {
  border-color: #1393f7;
  color: #1393f7;
}

.btn-style-five {
  border-color: #1393f7;
  color: #1393f7;
}

.btn-style-five:hover {
  background-color: #1393f7;
}

.main-slider .tp-caption .theme-btn.btn-style-two:hover {
  color: #1393f7;
}


/* TOP */
.main-header .header-upper .info-box .text a{
  color: #6f747c;
}

.main-header .header-upper .info-box .text a:hover {
  color: #1393f7;
}

.main-header .header-upper .info-box .title a {
  color: #fff;
}


/* MENU */
.main-menu .navigation > li:hover > a, .main-menu .navigation > li.current > a, .main-menu .navigation > li.current-menu-item > a {
  background-color: #1393f7;
}

.main-header .nav-outer .donate-btn {
  background-color: #1393f7;
}
/* dropdown */
.main-menu .navigation > li > ul {
  border-bottom: 3px solid #1393f7;
  width: 300px;
}

.main-menu .navigation > li > ul > li:hover > a {
  color: #1393f7;
}
/* sticky navigation */
.sticky-header .main-menu .navigation > li:hover > a, .sticky-header .main-menu .navigation > li.current > a, .sticky-header .main-menu .navigation > li.current-menu-item > a {
  background-color: #1393f7;
}



/* NAVIGATION SOTTO SLIDE */
.fluid-section-one .fluid-column:nth-child(1) {
  background-color: #2ca1fc;
}

.fluid-section-one .fluid-column:nth-child(2) {
  background-color: #229cfb;
}

.fluid-section-one .fluid-column:nth-child(3) {
  background-color: #1393f7;
}

.featured-cause-section .title {
  max-width: 1020px;
}



/* SERVICE BOX */
.service-box .inner-box {
    border: 1px solid #cacaca;
}

.service-box .inner-box .icon-box {
  color: #1393f7;
}

.service-box .inner-box:hover {
  background: #1393f7;
  border-color: #1393f7;
}

.sec-title .link-box a {
  border-bottom-color: #1393f7;
}



/* SERVICE BOX - Versione reversed*/
.service-box.reversed .inner-box{
  background:#1393f7;
  color:#ffffff;
  border-color:#1393f7;
}
.service-box.reversed .inner-box .icon-box{
  color:#ffffff;
}
.service-box.reversed .inner-box h3,
.service-box.reversed .inner-box p{
  color:#ffffff;
}
/* gestione dell'hover */
.service-box.reversed .inner-box:hover {
  border:1px solid #cacaca;
  background: none;
  color:#767676;
}

.service-box.reversed .inner-box:hover .icon-box{
  color:#1393f7;
}
.service-box.reversed .inner-box:hover p{
  color:#292929;
}
/* FINE SERVICE BOX - Versione reversed */



/* SETTORI */
.default-portfolio-item .overlay-inner {
  background: rgba(19,147,247,0.80);
}

.default-portfolio-item .overlay-box .more-link:hover {
  color: #1393f7;
}



/* FOOTER */
.main-footer .about-widget .title .count {
  color: #1393f7;
}

.main-footer .links-widget .list li a:hover {
  color: #1393f7;
}

.main-footer .footer-bottom .social-links li a:hover {
  background-color: #1393f7;
  border-color: #1393f7;
}

.main-footer .about-widget .title .count {
  font-family: 'Crimson Text',serif;
}

.scroll-to-top:hover {
  background-color: #1393f7;
}

.main-slider .tparrows.preview3:hover::after {
  background-color: #1393f7;
  border-color: #1393f7;
}

.fullwidth-section-one .content-column h2 span,
.fullwidth-section-one .content-column h1 span{
  font-size: 24px;
  font-weight: 300;
  color: #292929;
  margin-bottom: 25px;
  display: block;
  line-height: 36px;
}

.tcen{
  text-align: center;
}

.page-title.and2{
  padding:70px;
}

.custom-heading{
  font-size: 18px !important;
  margin-bottom: 30px;
}

/* FLUID SECTION */
.fluid-section-one .fluid-column .inner p{
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.2em;
  margin: 0;
}

.fluid-section-one .fluid-column .inner p.main{
  font-size:40px;
  font-weight:400;
  font-family: 'Crimson Text', serif;
  text-transform:capitalize;
  line-height:1.2em;
}
/* FINE FLUID SECTION */


/* CALL TO ACTION */
.call-to-action p.sub{
  font-size:36px;
  line-height:1.4em;
  margin-bottom:15px;
  font-family:'PT Serif',serif;
}

.call-to-action p.main{
  font-size:72px;
  font-weight:700;
  text-transform:capitalize;
  line-height:1.4em;
  margin-bottom:30px;
}
/* FINE CALL TO ACTION */



/* FOOTER */
.main-footer .footer-widget p{
  position: relative;
  font-size: 22px;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 25px;
  color: #ffffff;
}
/* FINE FOOTER */

strong{
  color:#505050;
}

.default-portfolio-item .image-box{
  border: solid 1px #e2e2e2;
}
.default-portfolio-item .image-box:before{
  background:rgba(0,0,0,0.50);
  background:-webkit-linear-gradient(top,rgba(0,0,0,0),rgba(0,0,0,0.15));
  background:-ms-linear-gradient(top,rgba(0,0,0,0),rgba(0,0,0,0.15));
  background:-o-linear-gradient(top,rgba(0,0,0,0),rgba(0,0,0,0.15));
}
.default-portfolio-item .image-box img{
  // width: auto;
  margin: 0 auto;
}

.gallery-section .sec-title h1{
  color: #292929;
  font-weight: 700;
  font-size: 38px;
}

.indica{
  margin-bottom: 40px;
}

a[name="map"]{
  /*position: relative;*/
  margin-top: -60px;
}

.contact-section .contact-info li .icon{
  color: #1393f7;
}

.btn-style-one{
  background: #1393f7;
  border-color: #1393f7;
}
.btn-style-one:hover{
  color: #1393f7;
}

.contact-us-form input:focus,
.contact-us-form select:focus,
.contact-us-form textarea:focus{
  border-color: #1393f7;
}

.contact-us-form input.error,
.contact-us-form select.error,
.contact-us-form textarea.error {
  border-color: #ffa7a5;
  background: #fff9f9;
}

#errore-snd{
  background: #ffd5d5;
  margin-bottom: 25px;
  padding: 15px;
  border-radius: 3px;
  border: solid 1px #f71313;
  color: #f71313;
}

#errore-snd span{
  color: #5a5a5a;
  font-size: 12px;
}

.succ-sub{
  background: #e9ffe9;
  border: solid 1px #00b500;
  margin-bottom: 25px;
  padding: 15px;
  color: #008000;
}

#errore-priv,
#errore-snd,
.succ-sub
{
  display: none;
}
#errore-priv{
  background: none;
}

.form-group div[id^='errore-']{
  background: #ffd5d5;
  padding: 0px 20px;
  font-size: 12px;
  color: red;
  /* border: solid 1px #330000; */
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

input[name="privacy"]{
  top:5px;
}

a:not([href]),
a[href="javascript:;"]{
  cursor: default;
}

button.close {
  z-index: 1000000;
  position: relative;
}

.overlink{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
}

.default-portfolio-item .overlay-box{
  background: rgba(67, 119, 198, 0.4);
}


.main-slider h1 {
    font-weight: 800;
    line-height: 1.2em;
    color: #ffffff;
    font-size: 64px;
    letter-spacing: 0px;
}

.default-portfolio-item .overlay-box p {
  font-size: 22px;
  color: #ffffff;
  font-weight: 700;
  line-height: 1.4em;
  margin-bottom: 20px;
}

.become-volunteer .content-box p {
    color: #292929;
    font-size: 30px;
    line-height: 1.4em;
    margin-bottom: 20px;
    font-weight: 300;
}
.become-volunteer .content-box p strong {
    font-size: 48px;
}

.nopad{
  padding: 20px 0;
}

.main-header .header-upper .social {
  padding: 0;
  margin-top: -17px;
}
.main-header {
  background: #2E3543;
}

.allarme { background-image: url('../images/background/allarmi-antifurto.jpg'); }
.bussole { background-image: url('../images/background/bussole-antirapina.jpg'); }
.chisiamo { background-image: url('../images/background/tecnosystem-chisiamo.jpg'); }
.controllo-accessi { background-image: url('../images/background/controllo-accessi.jpg'); }
.cta { background-image: url('../images/background/sicurezza-tecnosystem.jpg'); }
.prodotti-bg { background-image: url('../images/background/prodotti-tecnosystem.jpg'); }
.fumi-bg { background-image: url('../images/background/rivelazione-fumi.jpg'); }
.aziende-bg { background-image: url('../images/background/sicurezza-aziende.jpg'); }
.sicurezza-banche-bg { background-image: url('../images/background/sicurezza-banche.jpg'); }
.sicurezza-farmaceutica-bg { background-image: url('../images/background/sicurezza-farmaceutica.jpg'); }
.sicurezza-farmacie-bg { background-image: url('../images/background/sicurezza-farmacie.jpg'); }
.sicurezza-gdo-bg { background-image: url('../images/background/sicurezza-gdo.jpg'); }
.sicurezza-gioielliere-bg { background-image: url('../images/background/sicurezza-gioielliere.jpg'); }
.sicurezza-istituti-bancari-bg { background-image: url('../images/background/sicurezza-istituti-bancari.jpg'); }
.sicurezza-privato-bg { background-image: url('../images/background/sicurezza-privato.jpg'); }
.videosorveglianza-tvcc-bg { background-image: url('../images/background/videosorveglianza-tvcc.jpg'); }
.videosorveglianza-bg { background-image: url('../images/background/videosorveglianza.jpg'); }
.tvcc-bg { background-image: url('../images/background/tvcc.jpg'); }
.impianti-elettrici { background-image: url('../images/background/impianti-elettrici-industriali.jpg'); }
.building { background-image: url('../images/background/building40.jpg'); }
.cablaggio { background-image: url('../images/background/reti-dati-cablaggio.jpg'); }
.sicurezza-musei-bg { background-image: url('../images/background/sicurezza-musei.jpg'); }
.sicurezza-teatri-bg { background-image: url('../images/background/sicurezza-teatri.jpg'); }
.sicurezza-cinema-bg { background-image: url('../images/background/sicurezza-cinema.jpg'); }

.default-portfolio-item .image-box img.autowidth {
  width: auto;
}

.settori {
  padding-top: 80px;
}

.container-listato-settori {
  border: 3px solid #1393F7;
  border-radius: 3px;
  padding: 40px;
  position: relative;

  .tit-settori {
    background-color: #FFF;
    position: absolute;
    top: -20px;
    display: block;
    padding-left: 15px;
    padding-right: 15px;

    @media only screen and (min-width: 767px){
      display: inline-block;
      top: -34px;
      left: 50%;
      transform: translate(-50%, 0);
    }

    h1,
    h2,
    h3,
    h4 {
      color: #292929;
      font-weight: 700;
      font-size: 20px;

      @media only screen and (min-width: 767px){
        font-size: 38px;
      }
    }
  }

  .intro {
    text-align: center;
    margin-bottom: 50px;
    margin-top: 20px;
    font-weight: 700;
    color: #292929;
  }

  ul {
    li {
      text-indent: -1.3em;
      // margin-bottom: 16px;
      padding-left: 1.3em;
    }

    li:before {
      color: #1393F7;
      content: '■';
      padding-right: 10px;
      font-size: 17px;
    }
  }
}

ul.listato-new {
  li {
    text-indent: -1.3em;
    // margin-bottom: 16px;
    padding-left: 1.3em;
  }

  li:before {
    color: #1393F7;
    content: '■';
    padding-right: 10px;
    font-size: 17px;
  }
}

.container-img-building {
  text-align: center;
}


.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb60 {
  margin-bottom: 60px;
}

.ml10 {
  margin-left: 10px;
}

.ml20 {
  margin-left: 20px;
}

.ml30 {
  margin-left: 30px;
}

.ml40 {
  margin-left: 40px;
}

.ml50 {
  margin-left: 50px;
}

.ml60 {
  margin-left: 60px;
}

.lightbox-certificazioni {
  img {
    margin-bottom: 20px;
    margin-right: 20px;
  }
}