@import 'bootstrap';
@import 'revolution-slider';
@import 'slider-setting';
@import 'fontsito';
@import 'font-awesome';
@import 'flaticon';
@import 'animate';
@import 'owl';
@import 'jquery-ui';
@import 'jquery-fancybox';
@import 'style';
@import 'custom';
@import 'responsive';