

/*** 

====================================================================
	Main Slider style
====================================================================

***/

.main-slider{
	position:relative;
	z-index: 10;
	font-family: 'Montserrat', sans-serif;
}

.main-slider .tp-caption{
	z-index:5;
}

.main-slider .tp-dottedoverlay{
	background-color:rgba(0,0,0,0.40) !important;	
}

.main-slider h2{
	font-weight:800;
	line-height:1.2em;
	color:#ffffff;
	font-size:64px;
	letter-spacing:0px;
}

.main-slider .text{
	font-weight:400;
	line-height:1.8em;
	color:#ffffff;
	font-size:24px;
}

.main-slider .tp-caption .theme-btn{
	border-width: 2px !important;
	color:#ffffff;
}

.main-slider .tp-caption .theme-btn.btn-style-two:hover{
	color: #fc2c62;
}

.main-slider .tp-caption{
	z-index:5;
}

.main-slider .slide-overlay{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	background-color:rgba(0,0,0,0.40);	
}

.main-slider .tp-bannertimer,
.main-slider .tp-bullets,
.main-slider .tparrows.tp-leftarrow.preview3::before,
.main-slider .tparrows.tp-rightarrow.preview3::before{
	display:none !important;
}

.main-slider .tparrows.preview3,
.main-slider .tparrows.preview3{
	background:none !important;
	width:60px !important;
	overflow:hidden;
	margin-top:0px !important;
	height:70px !important;
	
}

.main-slider .tparrows.preview3::after{
	top:0px;
	background:rgba(255,255,255,0.90);
	color:#222222;
	font-size:24px;
	height:70px !important;
	line-height:70px;
	width:50px !important;
	border:none;
	padding:0px;
	border-radius:2px;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.main-slider .tparrows.tp-leftarrow.preview3::after{
	content: '\f104';
	font-family:'FontAwesome';
}

.main-slider .tparrows.tp-rightarrow.preview3::after{
	content: '\f105';
	font-family:'FontAwesome';
}

.main-slider .tparrows.preview3.tp-leftarrow{
	left:30px !important;
}

.main-slider .tparrows.preview3.tp-rightarrow{
	right:30px !important;
}

.main-slider .tparrows.preview3:hover::after{
	background:#fc2c62;
	border-color:#fc2c62;
	color:#ffffff;
}

.main-slider .tparrows.preview3 .tp-arr-iwrapper{
	visibility:hidden;
	opacity:0;
	display:none;	
}